import React from 'react';
import classnames from 'classnames';

import ChevronIcon from 'images/icon/chevron.inline.svg';
import LogoIcon from 'images/icon/logo.inline.svg';

import Link from '../Link';

import styles from './index.module.css';
import { useAuth } from 'models/auth';
import useMedia from 'util/hook/useMedia';

export interface Crumb {
	id?: string;
	name: string;
	link: string;
}

interface BreadcrumbsProperty {
	className?: string;
	crumbs: Crumb[];
}

const BreadcrumbItem: React.FC<Crumb> = ({ id, name, link }) => (
	<div className={styles.crumbsWrapper}>
		{link ? (
			<Link to={window.location.pathname === '/course-reservation/registrations/records' ? '/course-reservation/registrations/search' : window.location.pathname === '/course-reservation/registrations' ? '/appointments' : link}>
				{link && <ChevronIcon />}
				<span className={styles.name}>{name}</span>
			</Link>
		) : (
			<span className={classnames(styles.name, styles.lastName)} id={id}>
				{name}
			</span>
		)}
		<div className={styles.dividerWrapper}>
			<div className={styles.divider} />
		</div>
	</div>
);

const Breadcrumbs: React.FC<BreadcrumbsProperty> = ({ crumbs, className }) => {
	const [{ auth: {token} }] = useAuth();
	// const isLogin = Object.keys(userInfo).length > 0;
	const media = useMedia();
	return (
		<div className={classnames(styles.breadcrumbs, className)}>
			{(token === '' && media === 'desktop') && 
				<div className={styles.breadcrumbs}>
					<div className={styles.logo}>
						<LogoIcon />
					</div>
					<h3 className={styles.title}>禾馨醫療</h3>
					<div className={styles.bar}>|</div>
				</div>
			}
			{crumbs.map(crumb => (
				<BreadcrumbItem key={crumb.id} name={crumb.name} link={crumb.link} />
			))}
		</div>
	);
};

export default Breadcrumbs;
