import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/welcome',
	components: () => [import(/* webpackChunkName: 'welcome' */ './component')],
	render: ([Welcome]) => <Welcome />,
	onEnter: async () => {
		console.log('on Enter welcome start');
		console.log('on Enter welcome end');
	},
};

export default routes;
