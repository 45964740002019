import React from 'react';

import { getBranchList } from 'models/departmentRegistration';
import { getRegistrationRecords } from 'models/recordsRegistration';
import { CustomRoute } from 'util/hook/useRouter';
import history from 'store/history';

import RegistrationRecord from './Record';


const routes: CustomRoute = {
	path: '/records',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'registrationRecords' */ './component')],
			render: ([RegistrationRecords]) => <RegistrationRecords />,
			onEnter: async ({ next, store }) => {
				// webview而且未登入時 需通知app關閉webview並且app需導轉至登入頁, web的話直接返回登入頁
				const { user: {userInfo}, auth: {isWebview} } = store.getState();
				if (Object.keys(userInfo).length === 0) {
					if (isWebview) {
						window.flutter_inappwebview.callHandler('notLoginInNeedLoginPage', true);
					} else {
						history.push('/');
					}
					return;
				}
				console.log('on Enter registrations records');
				await store.dispatch(getBranchList());
				await store.dispatch(getRegistrationRecords());
				console.log('on Enter registrations records / end');
				const children = await next();
				return children;
			},
		},
		RegistrationRecord,
	],
};

export default routes;
