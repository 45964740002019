import { createAction, Action } from 'redux-actions';
import { Dispatch } from 'redux';

import {
	clearErrors,
	setCtrlErrors,
	setCtrlValue,
	updateCtrlValidity,
	updateValidity,
} from 'util/formSystem/formOperators';
import { GetState } from 'models/reducers';
import { FormErrors } from './formOption';

const setupFormModel = <F extends object>(
	formName: string,
	selector: (getState: GetState) => F,
) => {
	const setFormCtrlValue = createAction(
		`${formName}_SET_CTRL_VALUE`,
		<R>(ctrlName: string, value: R) =>
			(_: Dispatch, getState: GetState) => {
				const form = selector(getState);
				return setCtrlValue<F, R>(form as F, ctrlName as keyof F, value);
			},
	);

	const updateFormValidity = createAction(
		`${formName}_UPDATE_FORM_VALIDITY`,
		() => (_: Dispatch, getState: GetState) => {
			const form = selector(getState);
			return updateValidity<F>(form);
		},
	);

	const updateFormCtrlValidity = createAction(
		`${formName}_UPDATE_CTRL_VALIDITY`,
		(ctrlName: keyof F) => (_: Dispatch, getState: GetState) => {
			const form = selector(getState);
			return updateCtrlValidity<F>(form, ctrlName);
		},
	);

	const setFormCtrlErrors = createAction(
		`${formName}_SET_CTRL_ERRORS`,
		(ctrlName: keyof F, errors: FormErrors | null) => (_: Dispatch, getState: GetState) => {
			const form = selector(getState);
			return setCtrlErrors<F>(form, ctrlName, errors);
		},
	);

	const clearFormErrors = createAction(
		`${formName}_CLEAR_FORM_ERRORS`,
		() => (_: Dispatch, getState: GetState) => {
			const form = selector(getState);
			return clearErrors<F>(form);
		},
	);

	return {
		actions: {
			setFormCtrlValue,
			updateFormValidity,
			updateFormCtrlValidity,
			setFormCtrlErrors,
			clearFormErrors,
		},
		reducers: {
			[`${formName}_SET_CTRL_VALUE`]: (state: F, action: Action<Partial<F>>) => ({
				...state,
				...action.payload,
			}),
			[`${formName}_UPDATE_FORM_VALIDITY`]: (state: F, action: Action<F>) => ({
				...state,
				...action.payload,
			}),
			[`${formName}_UPDATE_CTRL_VALIDITY`]: (state: F, action: Action<Partial<F>>) => ({
				...state,
				...action.payload,
			}),
			[`${formName}_SET_CTRL_ERRORS`]: (state: F, action: Action<Partial<F>>) => ({
				...state,
				...action.payload,
			}),
			[`${formName}_CLEAR_FORM_ERRORS`]: (state: F, action: Action<F>) => ({
				...state,
				...action.payload,
			}),
		},
	};
};

export default setupFormModel;
