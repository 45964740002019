import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import history from 'store/history';

import { api } from 'util/api';
import { AestheticMedicineAppointmentListResource } from 'util/api/swaggerApi/data-contracts';

import { updateAccessToken } from './auth';
import { FormControl } from 'util/formSystem/formControl';
import setupFormModel from 'util/formSystem/setupForm';

import { GetState, State as GlobalState } from './reducers';

export interface SelectorValueType {
	doctor: string;
}

export interface SelectorType {
	type: string;
	value: string;
}

export interface State {
	loading: boolean;
	listLoading: boolean;
	allMedicalProgressList: AestheticMedicineAppointmentListResource[];
	currentFilteredList: AestheticMedicineAppointmentListResource[];
	currentBeautyListDetail: AestheticMedicineAppointmentListResource;
	selectedDepartment: FormControl<string>;
	selectedDistrict: FormControl<string>;
	allHospitalDistrict: any;
	allDistrictBranch: any;
}

export const defaultState: State = {
	loading: false,
	listLoading: false,
	allMedicalProgressList: [],
	currentFilteredList: [],
	currentBeautyListDetail: {} as AestheticMedicineAppointmentListResource,
	selectedDepartment: {
		value: '0',
		errors: null
	},
	selectedDistrict: {
		value: '0',
		errors: null
	},
	allHospitalDistrict: [],
	allDistrictBranch: []
};

export const formModelConfig = setupFormModel<State>('MEDICAL_PROGRESS_LIST_FORM', (getState: GetState) => {
	const { medicalProgressList } = getState();
	return medicalProgressList;
});

export const getMedicalProgressList = createAction('GET_MEDICAL_PROGRESS_LIST', () => async (dispatch: Dispatch, getState: GetState) => {
	const { v1MedicalProgress } = api;

	try {
		const { medicalProgressList: {selectedDistrict, selectedDepartment} } = getState();
		let param = {};

		param = {
			id: selectedDepartment.value
		}

		const { data } = await v1MedicalProgress(param);
		let sortedData: MedicalProgressListSelector[] = [];

		if (Array.isArray(data?.data)) {
			sortedData = [...data.data].sort((a, b) => {
				const nameA = a.clinicName ? a.clinicName.toUpperCase() : ''; // 處理 null 或 undefined
				const nameB = b.clinicName ? b.clinicName.toUpperCase() : '';
				return nameA.localeCompare(nameB);
			});
		} else {
			console.warn('data.data 不是陣列');
		}

			return sortedData;
		
	} catch (e) {
		console.log('medical progress list error', e);
		const { status } = e as { status: number };
		if (status === 401) {
			dispatch(updateAccessToken(''));
			history.push('/');
		}
		return [];
	}
});

export const getAllDistrictList = createAction('GET_HOSPITAL_DISTRICT', () => async (dispatch: Dispatch) => {
	const { v1HospitalDistrict } = api;

	try {
		const { data } = await v1HospitalDistrict({});
		const newList = data?.data;

		newList.unshift({
			id: '0',
			name: "全部",
			weight: 20
		})
		console.log('newlist', newList);
		return newList.map((item:any) => ({
			...item,
			value: item.id,
			optionName: item.name
		}));
	} catch (e) {
		console.log('medical progress list error', e);
		const { status } = e as { status: number };
		if (status === 401) {
			dispatch(updateAccessToken(''));
			history.push('/');
		}
		return [];
	}
});

export const getAllBranchtList = createAction('GET_DISTRICT_BRANCH', () => async (dispatch: Dispatch, getState: GetState) => {
	const { v1DistrictBranch } = api;

	try {
		const { medicalProgressList: {selectedDistrict} } = getState();
		// if(selectedDistrict.value === 'all') {
		// 	return [];
		// }
		const { data } = await v1DistrictBranch({id: selectedDistrict.value});
		const newList = data?.data;
		newList.unshift({
			id: '0',
			name: "全部院區",
			weight: 20
		})

		return newList.map((item:any) => ({
			...item,
			value: item.id,
			optionName: item.name
		}));
	} catch (e) {
		console.log('medical progress list error', e);
		const { status } = e as { status: number };
		if (status === 401) {
			dispatch(updateAccessToken(''));
			history.push('/');
		}
		return [];
	}
});

export const filterMedicalProgressList = createAction(
	'FILTER_MEDICAL_PROGRESS_LIST',
	() => (_: Dispatch, getState: GetState) => {
		const { medicalProgressList } = getState();
		const department = medicalProgressList.selectedDepartment.value;

		if (department === 'all') {
			return medicalProgressList.allMedicalProgressList;
		}
		if (department === '') {
			return [];
		}
		
		return [];
	},
);

export const cleanMedicalProgressSelectorValue = createAction(
	'CLEAN_MEDICAL_PROGRESS_SELECTOR_VALUE')

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	medicalProgressList: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			...formModelConfig.reducers,
			GET_MEDICAL_PROGRESS_LIST_PENDING: state => ({
				...state,
				listLoading: true,
			}),
			GET_MEDICAL_PROGRESS_LIST_FULFILLED: (
				state,
				action: Action<AestheticMedicineAppointmentListResource[]>,
			) => ({
				...state,
				allMedicalProgressList: [...action.payload],
				listLoading: false,
			}),
			GET_HOSPITAL_DISTRICT_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_HOSPITAL_DISTRICT_FULFILLED: (
				state,
				action: Action<AestheticMedicineAppointmentListResource[]>,
			) => ({
				...state,
				allHospitalDistrict: [...action.payload],
				loading: false,
			}),
			GET_DISTRICT_BRANCH_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_DISTRICT_BRANCH_FULFILLED: (
				state,
				action: Action<AestheticMedicineAppointmentListResource[]>,
			) => ({
				...state,
				allDistrictBranch: [...action.payload],
				loading: false,
			}),
			FILTER_INPATIENT_LIST: (state, action: Action<AestheticMedicineAppointmentListResource[]>) => ({
				...state,
				currentFilteredList: [...action.payload],
				loading: false,
			}),
			CLEAN_MEDICAL_PROGRESS_SELECTOR_VALUE: state => ({
				...state,
				selectedDepartment: {
					value: 'all',
					errors: null,
				},
			})
		},
		defaultState,
	),
};

const medicalProgressListActionsMap = {
	...formModelConfig.actions,
	getMedicalProgressList,
	getAllDistrictList,
	getAllBranchtList,
	cleanMedicalProgressSelectorValue,
	filterMedicalProgressList
};

const mapHooksToState = (state: GlobalState) => ({
	allMedicalProgressList: state.medicalProgressList.allMedicalProgressList,
	selectedDepartment: state.medicalProgressList.selectedDepartment,
	selectedDistrict: state.medicalProgressList.selectedDistrict,
	currentFilteredList: state.medicalProgressList.currentFilteredList,
	allHospitalDistrict: state.medicalProgressList.allHospitalDistrict,
	allDistrictBranch: state.medicalProgressList.allDistrictBranch,
	listLoading: state.medicalProgressList.listLoading
});

type MedicalProgressListSelector = ReturnType<typeof mapHooksToState>;
type medicalProgressListActionsMap = typeof medicalProgressListActionsMap;

export const useMedicalProgressList = () =>
	useRedux<MedicalProgressListSelector, medicalProgressListActionsMap>(
		mapHooksToState,
		medicalProgressListActionsMap,
	);