import React from 'react';
import classnames from 'classnames';

import styles from './index.module.css';

interface LoadingProperty {
	className?: string;
}

const Loading: React.FC<LoadingProperty> = ({ className }) => (
	<div className={styles.loadingWrapper}>
		<div className={classnames(styles.loading, className)} />
		<p>資料搜尋中...</p>
	</div>
);

export default Loading;
