import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import { clearSelectedOptions } from 'models/departmentRegistration';
import { clearSelectedOutpatientInfo } from 'models/outpatientRegistration';
// import { checkUserFirstDiagnosis } from 'models/user';

import RegistrationStart from './Start';
import RegistrationFailure from './Failure';
import RegistrationRecords from './Records';

const routes: CustomRoute = {
	path: '/registrations',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'registrations' */ './component')],
			render: ([Registrations]) => <Registrations />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter registrations');
				// await store.dispatch(checkUserFirstDiagnosis());
				store.dispatch(clearSelectedOptions());
				store.dispatch(clearSelectedOutpatientInfo());

				console.log('on Enter registrations / end');
				const children = await next();
				return children;
			},
		},
		RegistrationStart,
		RegistrationFailure,
		RegistrationRecords,
	],
};

export default routes;
