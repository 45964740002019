/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';

import styles from './index.module.css';

export type ButtonStyle = 'primary' | 'secondary' | 'tertiary' | 'text' | 'danger';

export type ButtonSize = 'xl' | 'l' | 'm' | 's';

export interface ButtonProperty extends ButtonHTMLAttributes<HTMLButtonElement> {
	classname: string;
	size: ButtonSize;
	buttonStyle: ButtonStyle;
}

const Button: React.FC<Partial<ButtonProperty>> = ({
	className,
	type = 'button',
	size = 's',
	buttonStyle = 'primary',
	disabled = false,
	children,
	onClick,
}) => (
	<button
		type={type}
		disabled={disabled}
		className={classnames(
			styles.button,
			className,
			styles[`button-${buttonStyle}`],
			styles[`button-size-${size}`],
		)}
		onClick={onClick}
	>
		{children}
	</button>
);

export default Button;
