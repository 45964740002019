import { handleActions, createAction, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { State as GlobalState } from './reducers';

export interface IModal {
	modalId: string;
}

export interface State {
	/**
	 * modal 資料列表（stack）
	 *
	 * @type {Modal}
	 * @memberof State
	 */
	modalList: IModal[];
}

/**
 * 初始使用者資訊狀態
 */
export const defaultState: State = {
	modalList: [],
};

export const openModal = createAction<string, string>('OPEN_MODAL', modalId => modalId);

/**
 * 關閉modal
 */
export const closeModal = createAction<string, string>('CLOSE_MODAL', modalId => modalId);

export const reducer = {
	modal: handleActions(
		{
			OPEN_MODAL: (state, action: Action<string>) => ({
				...state,
				modalList: [
					...state.modalList,
					{
						modalId: action.payload,
					},
				],
			}),
			CLOSE_MODAL: (state, action: Action<string>) => ({
				...state,
				modalList: state.modalList.filter(el => el.modalId !== action.payload),
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	modalList: state.modal.modalList,
});

const modalActionsMap = {
	openModal,
	closeModal,
};

type ModalSelector = ReturnType<typeof mapHooksToState>;
type ModalActionsMap = typeof modalActionsMap;

export const useModal = () =>
	useRedux<ModalSelector, ModalActionsMap>(mapHooksToState, modalActionsMap);
