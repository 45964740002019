import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getBranchList } from 'models/departmentRegistration';

import { getDoctorList, initListAndDoctorValue } from 'models/inpatientList';
import {
	getSurgeryRoomList,
	getSurgeryAppointmentList,
	setSurgerySelectorValue,
	filterSurgeryList,
	cleanSurgerySelectorValue,
	initSurgeryDoctorValue
} from 'models/surgeryList';
import { ROUTE_PATHS } from 'models/sideMenu';
import { checkAuthGuard } from 'models/auth';

import SurgeryListInner from './Inner';

const routes: CustomRoute = {
	path: '/surgery-list',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'surgeryList' */ './component')],
			render: ([SurgeryList]) => <SurgeryList />,
			onEnter: async ({ store, next }) => {
				await store.dispatch(getBranchList());
				// await store.dispatch(cleanSurgerySelectorValue());
				const allowEnter = checkAuthGuard(store, `/${ROUTE_PATHS.SURGERY_LIST}`);
				if (allowEnter) {
					const {
						user: { staffPermissionInfo },
					} = store.getState();
					if (staffPermissionInfo.isDoctor || staffPermissionInfo.hasDropdownPermission) {
						await store.dispatch(getSurgeryRoomList());
						await store.dispatch(getDoctorList());
						await store.dispatch(getSurgeryAppointmentList());
						await store.dispatch(initSurgeryDoctorValue());
					}
				}
				const children = await next();
				return children;
			},
		},
		SurgeryListInner,
	],
};
export default routes;
