import React from 'react';

import { getUserInfo } from 'models/user';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/user-info',
	components: () => [import(/* webpackChunkName: 'userInfo' */ './component')],
	render: ([UserInfo]) => <UserInfo />,
	onEnter: async ({store}) => {
		console.log('on Enter userInfo start');
		await store.dispatch(getUserInfo());
		console.log('on Enter userInfo end');
	},
};

export default routes;
