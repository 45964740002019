import { useMemo } from 'react';

import { Crumb } from 'components/atoms/Breadcrumbs';

import { useRouting } from 'models/routing';
import { RouteMenu, useSideMenu, ROUTE_PATHS } from 'models/sideMenu';

const createBreadcrumbs = (locatonPath: string, routeMenus: RouteMenu[]) => {
	const pathNameList = locatonPath.split('/').filter(Boolean);
	const currentMenus: RouteMenu[] = [];

	const addCrumbs = (pathList: string[], menus: RouteMenu[]) => {
		for (let i = 0; i < menus.length; i += 1) {
			const path = pathList[0];
			if (menus[i].to === `/${path}` || (path && menus[i].to && menus[i].to.includes('/:'))) {
				currentMenus.push(menus[i]);
				pathNameList.shift();
				addCrumbs(pathNameList, menus[i].subMenus);
			}
		}
	};

	addCrumbs(pathNameList, routeMenus);

	const crumbs: Crumb[] = currentMenus
		.reduce<Crumb[]>((prev, next, i) => {
			const parent = prev[prev.length - 1];

			const breadcrumb: Crumb = {
				id: next.id,
				name: next.title,
				link: '',
			};

			const isStaffLink = (currentMenus[0].to === `/${ROUTE_PATHS.STAFF}` || currentMenus[0].to === `/${ROUTE_PATHS.EYE_REPORT}`) && i === 0;
			if (currentMenus.length > 1 && i !== currentMenus.length - 1 && !isStaffLink)
			{
				if (currentMenus[0].to === `/${ROUTE_PATHS.STAFF}`) {
					breadcrumb.link = `/${ROUTE_PATHS.STAFF}${next.to}`;
				} else {
					breadcrumb.link = parent ? `${parent.link}${next.to}` : next.to;
				}
			}
			return [...prev, breadcrumb];
		}, [])
		.slice(currentMenus.length - 2);

	return crumbs;
};

const useBreadcrumbs = () => {
	const [{ routing }] = useRouting();
	const [{ routeMenus }] = useSideMenu();

	const breadcrumbs = useMemo(
		() => createBreadcrumbs(routing.pathname, routeMenus),
		[routing.pathname],
	);

	return [{ breadcrumbs }];
};

export default useBreadcrumbs;
