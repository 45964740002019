import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import history from 'store/history';

import { api } from 'util/api';
import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';

export interface HealthReport {
	rpStatus?: number;
	personId?: string;
	patientId?: string;
	patientName?: string;
	rpName?: string;
	sex?: string;
	birthday?: string;
	receivedate?: string;
	hospital?: string;
	doctor?: string;
	pdfReport?: string;
}

export interface NewHealthReport {
	id: number;
	date: string;
	rawDate: string;
	reportName: string;
	doctor: string;
	pdf: string;
}

export interface State {
	loading: boolean;
	healthReports: NewHealthReport[];
}

export const defaultState: State = {
	loading: false,
	healthReports: [],
};

export const getHealthReportList = createAction(
	'GET_HEALTH_REPORT_LIST', () =>
		async (dispatch: Dispatch, getState: GetState) => {

			const { v1UserInspectionReportList } = api;
			const {
				user: { userInfo: { twid } },
			} = getState();

			try {
				if (!twid) {
					return [];
				}
				const { data } = await v1UserInspectionReportList({ type: 'patient',twid });

				let id = 0;
				const newHealthReports = data?.data?.map((report: HealthReport) => {

					// 格式化日期
					let formattedDate = '';
					if (report.receivedate) {
						formattedDate = `${report.receivedate.slice(0, 4)}/${report.receivedate.slice(4, 6)}/${report.receivedate.slice(6)}`;
					}

					return {
						id: id++, // eslint-disable-line no-plusplus
						date: formattedDate,
						rawDate: report.receivedate,
						reportName: report.rpName,
						doctor: report.doctor,
						pdf: report.pdfReport,
					};
				});
				return newHealthReports as NewHealthReport[];
			} catch (e) {
				console.log('error', e);
				const { status } = e as { status: number };
				if (status === 401) {
					dispatch(updateAccessToken(''));
					history.push('/');
				}

				return [];
			}
		},
);

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	healthReports: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_HEALTH_REPORT_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_HEALTH_REPORT_LIST_FULFILLED: (state, action: Action<NewHealthReport[]>) => ({
				...state,
				healthReports: [...action.payload],
				loading: false,
			}),
		},
		defaultState,
	),
};

const healthReportsActionsMap = {
	getHealthReportList,
};

const mapHooksToState = (state: GlobalState) => ({
	healthReports: state.healthReports.healthReports,
});

type HealthReportsSelector = ReturnType<typeof mapHooksToState>;
type HealthReportsActionsMap = typeof healthReportsActionsMap;

export const useHealthReport = () =>
	useRedux<HealthReportsSelector, HealthReportsActionsMap>(
		mapHooksToState,
		healthReportsActionsMap,
	);
