import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
// import { checkAuthGuard } from 'models/auth';
// import { ROUTE_PATHS } from 'models/sideMenu';
import { getCheckInReportList } from 'models/checkInItem';

const routes: CustomRoute = {
	path: '/check-in-item',
	components: () => [import(/* webpackChunkName: 'welcome' */ './component')],
	render: ([CheckInItem]) => <CheckInItem />,
	onEnter: async ({ store }) => {
		console.log('on Enter CheckInItem start');
		console.log('on Enter CheckInItem end');
		await store.dispatch(getCheckInReportList());
	},
};

export default routes;

