import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getDoctorList, initListAndDoctorValue } from 'models/inpatientList';
import {
	getBeautyClinicList,
	cleanBeautySelectorValue,
	filterBeautyClinicList,
	setBeautySelectorValue,
} from 'models/beautyClinic';

import { ROUTE_PATHS } from 'models/sideMenu';
import { checkAuthGuard } from 'models/auth';

import BeautyClinicInner from './Inner';

const routes: CustomRoute = {
	path: '/beauty-clinic',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'beautyClinic' */ './component')],
			render: ([BeautyClinic]) => <BeautyClinic />,
			onEnter: async ({ store, next }) => {
				console.log('on Enter BeautyClinic start');
				store.dispatch(cleanBeautySelectorValue());

				const allowEnter = checkAuthGuard(store, `/${ROUTE_PATHS.BEAUTY_CLINIC}`);

				if (allowEnter) {
					const {
						user: { staffPermissionInfo },
					} = store.getState();
					if (staffPermissionInfo.isDoctor || staffPermissionInfo.hasDropdownPermission) {
						await store.dispatch(getDoctorList());
						await store.dispatch(getBeautyClinicList());
						await store.dispatch(
							initListAndDoctorValue(setBeautySelectorValue, filterBeautyClinicList),
						);
					}
				}
				console.log('on Enter BeautyClinic end');
				const children = await next();
				return children;
			},
		},
		BeautyClinicInner,
	],
};

export default routes;
