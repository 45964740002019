import { Dispatch } from 'redux';
import { handleActions, createAction } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { FormControl } from 'util/formSystem/formControl';
import { Validators } from 'util/formSystem/validatorFn';
import setupFormModel from 'util/formSystem/setupForm';
import { getRawValue } from 'util/formSystem/formOperators';
import { FormErrors } from 'util/formSystem/formOption';
import { V1AuthLoginCreateRequestPayload } from 'util/api/swaggerApi/data-contracts';

import { login } from 'models/auth';

import { GetState, State as GlobalState } from './reducers';

// For Global State usage
export interface State {
	birthday: FormControl<string>;
	twid: FormControl<string>;
	password: FormControl<string>;
}

export const defaultState: State = {
	birthday: {
		// value: '19490308',
		value: '',
		errors: null,
		options: {
			validators: [Validators.require],
		},
	},
	twid: {
		// 初始化身分證字號的 FormControl
		// value: 'F233333333',
		value: '',
		errors: null,
		options: {
			validators: [Validators.require, Validators.pattern(/^[A-Za-z0-9]{10}$/)],
		},
	},
	password: {
		// value: '0157987',
		value: '',
		errors: null,
		options: {
			validators: [Validators.require],
		},
	},
};

export interface FormItemPayload {
	key: string;
	value: HTMLInputElement['value'] | boolean;
	error?: FormErrors;
}

export const formModelConfig = setupFormModel<State>('LEAD_FORM', (getState: GetState) => {
	const { loginForm } = getState();
	return loginForm;
});

const submitForm = createAction(
	'LEAD_FORM_SUBMIT',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const {
			actions: { clearFormErrors },
		} = formModelConfig;

		dispatch(clearFormErrors());
		const { loginForm } = getState();
		const formData = getRawValue<State>(loginForm) as V1AuthLoginCreateRequestPayload;
		dispatch(login(formData));
	},
);

export const clearLoginForm = createAction('CLEAR_LOGIN_FORM');

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	loginForm: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			...formModelConfig.reducers,
			CLEAR_LOGIN_FORM: () => ({
				...defaultState,
			}),
		},
		defaultState,
	),
};

const loginFormActionsMap = {
	...formModelConfig.actions,
	submitForm,
	clearLoginForm,
};

const mapHooksToState = (state: GlobalState) => ({
	loginForm: state.loginForm,
});

type LoginFormSelector = ReturnType<typeof mapHooksToState>;
type LoginFormActionsMap = typeof loginFormActionsMap;

export const useLoginForm = () =>
	useRedux<LoginFormSelector, LoginFormActionsMap>(mapHooksToState, loginFormActionsMap);
