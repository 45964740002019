import React from 'react';

import SideMenu from 'components/molecules/SideMenu';

import LogoIcon from 'images/icon/logo.inline.svg';

import styles from './index.module.css';

const SideBar: React.FC = () => (
	<nav className={styles.sideBar}>
		<div className={styles.logo}>
			<LogoIcon />
			<h3 className={styles.title}>禾馨醫療</h3>
			{/* <Label context="安和院區" /> */}
		</div>
		<SideMenu />
	</nav>
);

export default SideBar;
