// eslint-disable-next-line no-shadow
export enum StorageType {
	LOCAL = 'local',
	SESSION = 'session',
}

export const STORAGE_KEY = {
	TOKEN: 'token',
	SELECTED_DEPARTMENT: 'selectedDepartment',
	SELECTED_BRANCH: 'selectedBranch',
	SELECTED_TYPE: 'selectedType',
	SELECTED_TIME: 'selectedTime',
	SELECTED_DOCTOR: 'selectedDoctor',
	SELECTED_OUTPATIENT: 'selectedOutpatient'
};

const prefix = (key: string) => `dianthus-${key}`;

export const setItemToStorage = <T>(key: string, value: T, type = StorageType.SESSION) => {
	const prefixKey = prefix(key);
	let storage = window.sessionStorage;
	if (type === StorageType.LOCAL) {
		storage = window.localStorage;
	}
	storage.setItem(prefixKey, JSON.stringify(value));
};

export const getItemFromStorage = <T>(key: string, type = StorageType.SESSION): T | null => {
	const prefixKey = prefix(key);
	let storage = window.sessionStorage;

	if (type === StorageType.LOCAL) {
		storage = window.localStorage;
	}
	const value = storage.getItem(prefixKey);
	return value ? JSON.parse(value) : null;
};

export const removeItemInStorage = (key: string, type = StorageType.SESSION) => {
	const prefixKey = prefix(key);
	let storage = window.sessionStorage;
	if (type === StorageType.LOCAL) {
		storage = window.localStorage;
	}
	storage.removeItem(prefixKey);
};
