import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import history from 'store/history';

import { api } from 'util/api';
import { EyeCheckImageListRequestParams } from 'util/api/swaggerApi/data-contracts';

import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';

interface Record {
	uid?: string;
	date?: string;
	description?: string;
	dr?: string;
	photo?: string[];
	video?: string[];
	index?: string;
}

export interface MediaType {
	type: string;
	url: string;
	combineIndex: number;
}

export interface EyeCheckImageReportType {
	id: number;
	date: string;
	rawDate: string;
	description: string;
	doctorName: string;
	summary: string;
	reportBase64: string;
	photo: string[];
	institution: string;
	video: string[];
	combineMedia: MediaType[];
	groupedMedia: MediaType[][];
}

export const getEyeCheckImageListReportList = createAction(
	'GET_EYE_CHECK_IMAGE',
	() =>
		async (dispatch: Dispatch, getState: GetState) => {
			const {
				user: {
					userInfo: { patientNo },
				},
			} = getState();

			const params: EyeCheckImageListRequestParams = {
				patientNo: patientNo || ''
			};

			let response;
			const { v1EyeReportList } = api;

			try {
				response = await v1EyeReportList(params);
			} catch (e) {
				console.log('image report error', e);
				const { status } = e as { status: number };
				if (status === 401) {
					dispatch(updateAccessToken(''));
					history.push('/');
				}
				return [];
			}

			const { data } = response;

			if (!data?.data) {
				return [];
			}
			return data?.data
			// return processedRecords;
		},
);

export interface State {
	loading: boolean;
	reports: EyeCheckImageReportType[];
}

export const defaultState: State = {
	loading: false,
	reports: [],
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	eyeCheckImageReports: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_EYE_CHECK_IMAGE_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_EYE_CHECK_IMAGE_FULFILLED: (state, action: Action<EyeCheckImageReportType[]>) => ({
				...state,
				reports: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const eyeCheckImageReportActionsMap = {
	getEyeCheckImageListReportList,
};

const mapHooksToState = (state: GlobalState) => ({
	eyeCheckImageReports: state.eyeCheckImageReports.reports,
});

type EyeCheckImageReportSelector = ReturnType<typeof mapHooksToState>;
type EyeCheckImageReportActionsMap = typeof eyeCheckImageReportActionsMap;

export const useEyeCheckImageReport = () =>
	useRedux<EyeCheckImageReportSelector, EyeCheckImageReportActionsMap>(mapHooksToState, eyeCheckImageReportActionsMap);
