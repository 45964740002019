import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { State as GlobalState } from './reducers';

export interface Staff {
	id: string;
	avatar: string;
	name: string;
	mediaRecordNumber: string;
	identityId: string;
	mobile: string;
	email: string;
}

// For Global State usage
export interface State {
	loading: boolean;
	staff: Staff;
}

export const defaultState: State = {
	loading: false,
	staff: {
		id: '',
		avatar: '',
		name: '',
		mediaRecordNumber: '',
		identityId: '',
		mobile: '',
		email: '',
	},
};

export const cleanMember = createAction('CLEAN_MEMBER');

export const reducer = {
	members: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_MEMBER_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_MEMBER_FULFILLED: (state, action: Action<Staff>) => ({
				...state,
				staff: { ...action.payload },
				loading: false,
			}),

			CLEAN_MEMBER: state => ({
				...state,
				staffs: defaultState.staff,
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState): { member: Staff } => ({
	member: state.members.staff,
});

const memberActionsMap = {
	cleanMember,
};

type MemberSelector = ReturnType<typeof mapHooksToState>;
type MemberActionsMap = typeof memberActionsMap;

export const useMember = () =>
	useRedux<MemberSelector, MemberActionsMap>(mapHooksToState, memberActionsMap);
