import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import { clearSearchInfoForm } from 'models/courseRegistrationsSearch';

const routes: CustomRoute = {
	path: '/search',
	components: () => [import(/* webpackChunkName: 'registrationSearch' */ './component')],
	render: ([RegistrationSearch]) => <RegistrationSearch />,
	onEnter: async ({ store }) => {
		console.log('on Enter registration search');
		store.dispatch(clearSearchInfoForm());
	},
};

export default routes;
