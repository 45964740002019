import React from 'react';

import SideBar from 'components/organisms/SideBar';
import Header from 'components/organisms/Header';
import Toast from 'components/atoms/Toast';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import Alert from 'components/atoms/Alert';

import { useRouting } from 'models/routing';
import { ROUTE_PATHS } from 'models/sideMenu';

import useMedia from 'util/hook/useMedia';
import useBreadcrumbs from 'util/hook/useBreadcrumbs';
import { isNeedTokenPage, isNotNeedTokenPage } from 'util/routeHelper';
import { useUser } from 'models/user';

import styles from './index.module.css';

interface AppProperty {
	children: React.ReactNode;
}

const App: React.FC<AppProperty> = ({ children }) => {
	// const urlParams = new URLSearchParams(window.location.search);
	// const source = urlParams.get('src');
	const [
		{
			routing: { pathname },
		},
	] = useRouting();
	const [{ userInfo, device }] = useUser();
	const media = useMedia();
	const [{ breadcrumbs }] = useBreadcrumbs();
	const isWelcomePage = pathname === `/${ROUTE_PATHS.WELCOME}`;
	const isNavigationShow = (isNeedTokenPage(pathname) && !isWelcomePage) || (isNotNeedTokenPage(window.location.pathname) && Object.keys(userInfo).length > 0);
	const isAppointmentNotLogin = (isNotNeedTokenPage(window.location.pathname) && Object.keys(userInfo).length === 0);

	return (
		<>
			{/* {isNavigationShow ? (
				<div className={styles.appWrapper}>
					{media === 'desktop' && isAppointmentLogin && <SideBar />}
					<section className={styles.appContent}>
						<Header />
						<div className={styles.wrapper}>
							{media === 'mobile' && (
								<Breadcrumbs crumbs={breadcrumbs} className={styles.breadcrumbs} />
							)}
							{children}
						</div>
					</section>
				</div>
			) : (
				<div className={styles.appWrapper}>{children}</div>
			)} */}
			{isNavigationShow ? (
				<div className={styles.appWrapper}>
					{media === 'desktop' && <SideBar />}
					<section className={styles.appContent}>
						<Header />
						<div className={styles.wrapper}>
							{media === 'mobile' && device !== 'app' && (
								<Breadcrumbs crumbs={breadcrumbs} className={styles.breadcrumbs} />
							)}
							{children}
						</div>
					</section>
				</div>
			) :
				isAppointmentNotLogin ? (
					<div className={styles.appWrapper}>
						<section className={styles.appContent}>
							<Header />
							<div className={styles.wrapper}>
								{media === 'mobile' && device !== 'app' && (
									<Breadcrumbs crumbs={breadcrumbs} className={styles.breadcrumbs} />
								)}
								{children}
							</div>
						</section>
					</div>
				) : (
					<div className={styles.appWrapper}>{children}</div>
				)
			}
			<Toast />
			<Alert />
		</>
	);
};

export default App;
