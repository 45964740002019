import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import { nanoid } from 'nanoid';

import { ApiError, api } from 'util/api';
import { FormControl } from 'util/formSystem/formControl';
import { Validators } from 'util/formSystem/validatorFn';
import setupFormModel from 'util/formSystem/setupForm';
import { V1UserUpdateRequestParams, V1UserPasswordUpdateRequestPayload, UserResource } from 'util/api/swaggerApi/data-contracts';
import { getRawValue } from 'util/formSystem/formOperators';
import { ValidationRules } from 'util/formSystem/validationRules';
import { ValidatorFnType } from 'util/formSystem/formOption';
import { EMAIL_REGEXP, PASSWORD_REGEXP } from 'util/regexp';

import { setUserInfo } from './user';
import { ToastStatus, openToast } from './toast';
import { GetState, State as GlobalState } from './reducers';

export interface State {
	loading: boolean;
	email: FormControl<string>;
	oldPassword: FormControl<string>;
	password: FormControl<string>;
	confirmPassword: FormControl<string>;
}

export const FIELD_NAME_MAP = {
	birthday: '生日',
	gender: '性別',
	twid: '身分證字號',
	phone: '手機號碼',
	password: '密碼',
	email: '電子信箱',
	address: '地址',
};

export const defaultState: State = {
	loading: false,
	email: {
		value: '',
		errors: null,
		options: {
			validators: [Validators.pattern(EMAIL_REGEXP)],
		},
	},
	oldPassword: {
		value: '',
		errors: null,
		options: {
			validators: [],
		},
	},
	password: {
		value: '',
		errors: null,
		options: {
			validators: [Validators.pattern(PASSWORD_REGEXP)],
		},
	},
	confirmPassword: {
		value: '',
		errors: null,
		options: {
			validators: [
				Validators.equalValue('password') as ValidatorFnType,
			],
		},
	},
};

export const formModelConfig = setupFormModel<State>('EDIT_USER_INFO_FORM', (getState: GetState) => {
	const { editUserInfoForm } = getState();
	return editUserInfoForm;
});

export const submitEditUserInfoForm = createAction(
	'SUBMIT_EDIT_USER_INFO_FORM',
	(
		fieldName: keyof (V1UserUpdateRequestParams | V1UserPasswordUpdateRequestPayload),
		callback?: () => void,
	) =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { v1UserUpdate, v1UserPasswordUpdate } = api;

			const { editUserInfoForm } = getState();
			const formRawValue = getRawValue<Partial<State>>(editUserInfoForm);
			const fieldValue: string = formRawValue[fieldName];
			const userUpdateParam: { [key: string]: string; } = {
				[fieldName]: fieldValue,
			};

			try {
				// 修改密碼的 API
				if (fieldName === 'password') {
					userUpdateParam.old_password = formRawValue.oldPassword as string;
					await v1UserPasswordUpdate(userUpdateParam as unknown as V1UserPasswordUpdateRequestPayload);
				} else { // 修改會員資料的 API
					const { data } = await v1UserUpdate(userUpdateParam as V1UserUpdateRequestParams);
					const user: UserResource = data?.data || {};
					dispatch(setUserInfo(user));
				}
				// Callback
				if (callback) {
					callback();
				}
				dispatch(openToast({ toastId: nanoid(), status: ToastStatus.SUCCESS, text: `${FIELD_NAME_MAP[fieldName]}變更成功` }));
			} catch (error) {
				const apiError = (error as ApiError).error;
				const status = apiError?.status;
				const message = apiError?.message || '';
				const extra = apiError?.extra || {};
				const extraKeys = Object.keys(extra) as (keyof State)[];

				if (status === 422) {
					extraKeys.forEach(keyName => {
						dispatch(formModelConfig.actions.setFormCtrlErrors(keyName, { [ValidationRules.PATTERN]: true }));
					});
					return;
				}

				// 其餘狀況統一使用 toast 顯示 message
				dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: message }));
			}
		}
);

export const clearEditUserInfoForm = createAction(
	'CLEAR_EDIT_USER_INFO_FORM',
	() => async (_dispatch: Dispatch, getState: GetState) => {
		const { user } = getState();
		return user.userInfo.gender;
	}
);

export const reducer = {
	editUserInfoForm: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			...formModelConfig.reducers,
			SUBMIT_EDIT_USER_INFO_FORM_PENDING: state => ({
				...state,
				loading: true,
			}),
			SUBMIT_EDIT_USER_INFO_FORM_FULFILLED: state => ({
				...state,
				loading: false,
			}),
			CLEAR_EDIT_USER_INFO_FORM_FULFILLED: () => ({
				...defaultState,
			}),
		},
		defaultState,
	),
};

const editUserInfoFormActionsMap = {
	...formModelConfig.actions,
	submitEditUserInfoForm,
	clearEditUserInfoForm,
};

const mapHooksToState = (state: GlobalState) => ({
	editUserInfoForm: state.editUserInfoForm,
});

type EditUserInfoFormSelector = ReturnType<typeof mapHooksToState>;
type EditUserInfoFormActionsMap = typeof editUserInfoFormActionsMap;

export const useEditUserInfoForm = () =>
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	useRedux<EditUserInfoFormSelector, EditUserInfoFormActionsMap>(mapHooksToState, editUserInfoFormActionsMap);
