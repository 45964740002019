import React from 'react';

import history from 'store/history';

import { ROUTE_PATHS } from 'models/sideMenu';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/forgot-password',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '/identity-verification',
			components: () => [import(/* webpackChunkName: 'identityVerification' */ 'layouts/ForgotPassword/IdentityVerification')],
			render: ([IdentityVerification]) => <IdentityVerification />,
			onEnter: async ({ next }) => {
				console.log('on Enter identity-verification');
				const children = await next();
				console.log('on Enter identity-verification / end');
				return children;
			},
		},
		{
			path: '/get-password',
			components: () => [import(/* webpackChunkName: 'getPassword' */ 'layouts/ForgotPassword/GetPassword')],
			render: ([GetPassword]) => <GetPassword />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter get-password');
				const { forgotPassword } = store.getState();
				const isForgotPasswordFormValid = forgotPassword.twid.value
					&& (forgotPassword.email || forgotPassword.phone);
				if (!isForgotPasswordFormValid) {
					history.push(`/${ROUTE_PATHS.FORGOT_PASSWORD}/${ROUTE_PATHS.IDENTITY_VERIFICATION}`);
				}
				const children = await next();
				console.log('on Enter get-password / end');
				return children;
			},
		},
	],
};

export default routes;
