import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getBranchList } from 'models/departmentRegistration';

import {
	getAllInpatientList,
	getDoctorList,
	initInpatientListAndDoctorValue,
	cleanInpatientSelectorValue,
} from 'models/inpatientList';
import { ROUTE_PATHS } from 'models/sideMenu';
import { checkAuthGuard } from 'models/auth';

import InpatientListInner from './Inner';

const routes: CustomRoute = {
	path: '/inpatient-list',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'inpatientList' */ './component')],
			render: ([InpatientList]) => <InpatientList />,
			onEnter: async ({ store, next }) => {
				console.log('on Enter inpatient list');
				await store.dispatch(getBranchList());
				// store.dispatch(cleanInpatientSelectorValue());
				const allowEnter = checkAuthGuard(store, `/${ROUTE_PATHS.INPATIENT_LIST}`);
				if (allowEnter) {
					const {
						user: { staffPermissionInfo },
					} = store.getState();
					if (staffPermissionInfo.isDoctor || staffPermissionInfo.hasDropdownPermission) {
						await store.dispatch(getAllInpatientList());
						await store.dispatch(getDoctorList());
						await store.dispatch(initInpatientListAndDoctorValue());
					}
				}
				const children = await next();
				return children;
			},
		},
		InpatientListInner,
	],
};
export default routes;
