import OBGYNIcon from 'images/departments/OBGYN.inline.svg';
import PEDIcon from 'images/departments/PED.inline.svg';
import DNATESTIcon from 'images/departments/DNATEST.inline.svg';
import ORTHOIcon from 'images/departments/ORTHO.inline.svg';
import DENIcon from 'images/departments/DEN.inline.svg';
import PMIcon from 'images/departments/PM.inline.svg';
import METAIcon from 'images/departments/META.inline.svg';
import GBCIcon from 'images/departments/GBC.inline.svg';
import PMRIcon from 'images/departments/PMR.inline.svg';
import METALWIcon from 'images/departments/METALW.inline.svg';
import OPHIcon from 'images/departments/OPH.inline.svg';
import LACIcon from 'images/departments/LAC.inline.svg';
import GEIcon from 'images/departments/GE.inline.svg';
import AIRIcon from 'images/departments/AIR.inline.svg';
import NEUROIcon from 'images/departments/NEURO.inline.svg';
import RSIcon from 'images/departments/RS.inline.svg';
import URIcon from 'images/departments/UR.inline.svg';
import VSIcon from 'images/departments/VS.inline.svg';
import PTIcon from 'images/departments/PT.inline.svg';
import PSYIcon from 'images/departments/PSY.inline.svg';
import PEDSIcon from 'images/departments/PEDS.inline.svg';
import HEMAIcon from 'images/departments/HEMA.inline.svg';
import FERIcon from 'images/departments/FER.inline.svg';
import DermIcon from 'images/departments/DERM.inline.svg';
import CVIcon from 'images/departments/CV.inline.svg';
import BSIcon from 'images/departments/BS.inline.svg';
import AESMEDIcon from 'images/departments/AESMED.inline.svg';

import LogoPink from 'images/icon/logo-small.inline.svg';
import LogoPurple from 'images/icon/logo-purple.inline.svg';
import LogoBlue from 'images/icon/logo-blue.inline.svg';


export type HospitalBranch = 'xs' | 'ty' | 'mq' | 'yz' | 'sl' | 'tx';

// 台中院區在選項要顯示為'安和院區'，但是 api 的回傳值會是'台中'
export const institutionOptions = [
	{ id: 'all', value: 'all', optionName: '所有院區', relativeOptions: [] },
	{ id: 'xs', value: 'xs', optionName: '新生院區', relativeOptions: [] },
	{ id: 'mq', value: 'mq', optionName: '民權院區', relativeOptions: [] },
	{ id: 'yz', value: 'yz', optionName: '怡仁院區', relativeOptions: [] },
	{ id: 'sl', value: 'sl', optionName: '士林月中', relativeOptions: [] },
	{ id: 'ty', value: 'ty', optionName: '桃園院區', relativeOptions: [] },
	{ id: 'tx', value: 'tx', optionName: '安和院區', relativeOptions: [] },
];

export const hospitalBranchMapping: { [key: string]: HospitalBranch } = {
	'桃園': 'ty',
	'民權': 'mq',
	'新生': 'xs',
	'怡仁': 'yz',
	'士林月中': 'sl',
	'安和': 'tx',
};

export const departmentsIconMapping: { [key: string]: string } = {
	OBGYN: OBGYNIcon,
	PED: PEDIcon,
	DNATEST: DNATESTIcon,
	ORTHO: ORTHOIcon,
	DEN: DENIcon,
	PM: PMIcon,
	META: METAIcon,
	GBC: GBCIcon,
	PMR: PMRIcon,
	METALW: METALWIcon,
	OPH: OPHIcon,
	LAC: LACIcon,
	GE: GEIcon,
	AIR: AIRIcon,
	NEURO: NEUROIcon,
	RS: RSIcon,
	UR: URIcon,
	VS: VSIcon,
	PT: PTIcon,
	PSY: PSYIcon,
	PEDS: PEDSIcon,
	HEMA: HEMAIcon,
	FER: FERIcon,
	DERM: DermIcon,
	CV: CVIcon,
	BS: BSIcon,
	AESMED: AESMEDIcon,
};

export const branchIconColorMapping: { [key: string]: string } = {
	pink: LogoPink,
	purple: LogoPurple,
	green: LogoBlue,
};

