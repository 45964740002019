import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getGlaucomaTrackListReportList } from 'models/glaucomaTrackReport';
import { ROUTE_PATHS } from 'models/sideMenu';
import { checkAuthGuard } from 'models/auth';

// import InpatientListInner from './Inner';

const routes: CustomRoute = {
	path: '/glaucoma-track',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'inpatientList' */ './component')],
			render: ([GlaucomaTrack]) => <GlaucomaTrack />,
			onEnter: async ({ store, next }) => {
				console.log('on Glaucoma Track Page');
				const allowEnter = checkAuthGuard(store, `/${ROUTE_PATHS.GLAUCOMA_TRACK}`);
				if (allowEnter) {
					await store.dispatch(getGlaucomaTrackListReportList());
				}
				const children = await next();
				return children;
			},
		},
		// InpatientListInner,
	],
};
export default routes;
