import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import RegistrationRoute from './Registration';


const routes: CustomRoute = {
	path: '/course-reservation',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'CourseReservation' */ './Registration/component')],
			render: ([CourseReservation]) => <CourseReservation />,
			onEnter: async ({ next }) => {
				console.log('on Enter CourseReservation');

				console.log('on Enter CourseReservation / end');
				const children = await next();
				return children;
			},
		},
		RegistrationRoute,
	],
};

export default routes;
