import React from 'react';

import history from 'store/history';

import { ROUTE_PATHS } from 'models/sideMenu';

import { CustomRoute } from 'util/hook/useRouter';
import { isFormValid } from 'util/formSystem/formOperators';

const routes: CustomRoute = {
	path: '/sign-up',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '/basic-info',
			components: () => [import(/* webpackChunkName: 'basicInfo' */ 'layouts/SignUp/BasicInfo')],
			render: ([BasicInfo]) => <BasicInfo />,
			onEnter: async ({ next }) => {
				console.log('on Enter basic-info start');
				const children = await next();
				console.log('on Enter basic-info end');
				return children;
			},	
		},
		{
			path: '/get-password',
			components: () => [import(/* webpackChunkName: 'basicInfo' */ 'layouts/SignUp/GetPassword')],
			render: ([GetPassword]) => <GetPassword />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter get-password start');
				const { signUp } = store.getState();
				const hasPhoneOrEmail = signUp.phone.value || signUp.email.value;
				const isSignUpFormValid = hasPhoneOrEmail && isFormValid(signUp);
				if (!isSignUpFormValid) {
					history.push(`/${ROUTE_PATHS.SIGN_UP}/${ROUTE_PATHS.BASIC_INFO}`);
				}
				const children = await next();
				console.log('on Enter get-password end');
				return children;
			},	
		},
	]
};
export default routes;
