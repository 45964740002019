import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import history from 'store/history';
import 'dayjs/locale/zh-tw'; 
import { ToastStatus, openToast } from './toast';
import { nanoid } from 'nanoid';

import { api } from 'util/api';
import setupFormModel from 'util/formSystem/setupForm';

import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';
import { FormControl } from 'util/formSystem/formControl';

export interface State {
	loading: boolean;
	btnLoading: boolean;
	records: [];
	record: any;
	selectedCourseRecord: FormControl<any>;
}

export const defaultState: State = {
	loading: false,
	btnLoading: false,
	records: [],
	record: {},
	selectedCourseRecord: {} as FormControl<any>,
};

export const formModelConfig = setupFormModel<State>('REGISTRATION_RECORD_FORM', (getState: GetState) => {
	const { courseRegistrationRecords } = getState();
	return courseRegistrationRecords;
});

export const getRegistrationRecords = createAction(
	'GET_REGISTRATION_RECORDS', () =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { v1CourseBooking, v1StaffCourseBooking } = api;
			const { courseRegistrationsSearch: {searchInfo}, user: {isAppStaff} } = getState();

			let recordsData:any= [];
			try {
				const { data } = isAppStaff ? await v1StaffCourseBooking() : await v1CourseBooking({identity: searchInfo.identity});

				if (data?.data.length === 0) {
					return [];
				}

				return data?.data?.map(item => ({
					...item,
					date: item?.startAt?.split(' ')[0],
					time: item?.startAt?.split(' ')[1],
				}));
			} catch(error) {
				console.log('error', error);
				const { status } = error as { status: number };
				if (status === 401) {
					dispatch(updateAccessToken(''));
					history.push('/');
				}
			}

			return recordsData;
		}	
);

export const getRegistrationRecord = createAction(
	'GET_REGISTRATION_RECORD', () =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { v1CourseBookingDetail, v1StaffCourseBookingDetail } = api;
			const { courseRegistrationsSearch: {searchInfo}, courseRegistrationRecords: {selectedCourseRecord}, user: {isAppStaff} } = getState();

			try {
				// const params = {
				// 	identity: isAppStaff ? null :  searchInfo.identity,
				// 	courseId: selectedCourseRecord.value.id
				// }

				let params:any = {
					courseId: selectedCourseRecord.value.id
				}

				if (!isAppStaff)  {
					params = {
						...params,
						identity: searchInfo.identity,
					}
				}

				const { data } = isAppStaff ?  await v1StaffCourseBookingDetail(params) : await v1CourseBookingDetail(params);
				
				return data?.data?.map(item => ({
					...item,
					date: item?.startAt?.split(' ')[0],
					time: item?.startAt?.split(' ')[1],
				}))[0];
			} catch(error) {
				console.log('error', error);
				const { status } = error as { status: number };
				if (status === 401) {
					dispatch(updateAccessToken(''));
					history.push('/');
				}
			}
		}	
);

export const cancelCourseRegistrationRecord = createAction(
	'CANCEL_REGISTRATION_RECORD', () =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { v1CourseJoinCancel, v1StaffCourseJoinCancel } = api;
			const { courseRegistrationsSearch: { searchInfo }, courseRegistrationRecords: { record }, user: { isAppStaff } } = getState();

			try {
				let params:any = {
					courseId: record.id
				}

				if (!isAppStaff)  {
					params = {
						...params,
						identity: searchInfo.identity,
					}
				}
				
				const { status, message } = isAppStaff ? await v1StaffCourseJoinCancel(params) : await v1CourseJoinCancel(params);

				if (status === 200) {
					dispatch(openToast({ toastId: nanoid(), status: ToastStatus.SUCCESS, text: message }));
					history.push({
						pathname: `${history.location.pathname}/cancel`,
					})
				}
			} catch (error:any) {
				console.log('cancel error', error);
				const { status } = error as { status: number };
				if (status === 401) {
					dispatch(updateAccessToken(''));
					history.push('/');
				} else if (status === 400) {
					dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: error.error.message }));
					// advanceToNextStep({ result: REGISTRATION_RESULT_QUERY.FAILED });
				} else if (status === 500) {
					dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: error.error.message }));
					// history.push(
					// 	`${ROUTE_PATHS.REGISTRATION_FAILURE}`,
					// );
				}
			}
			return null;
		}
);
// online-registration/record
export const clearSelectedRecord = createAction('CLEAR_SELECTED_RECORD', () => (dispatch: Dispatch) => {
	dispatch(formModelConfig.actions.setFormCtrlValue('selectedRecord', {}));
});


export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	courseRegistrationRecords: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			...formModelConfig.reducers,
			GET_REGISTRATION_RECORDS_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_REGISTRATION_RECORDS_FULFILLED: (state, action) => ({
				...state,
				records: action.payload,
				loading: false,
			}),
			GET_REGISTRATION_RECORD_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_REGISTRATION_RECORD_FULFILLED: (state, action) => ({
				...state,
				record: action.payload,
				loading: false,
			}),
			CANCEL_REGISTRATION_RECORD_PENDING: state => ({
				...state,
				btnLoading: true,
			}),
			CANCEL_REGISTRATION_RECORD_FULFILLED: (state) => ({
				...state,
				btnLoading: false,
			}),
		},
		defaultState,
	),
};

const recordsRegistrationActionsMap = {
	...formModelConfig.actions,
	getRegistrationRecords,
	getRegistrationRecord,
	clearSelectedRecord,
	cancelCourseRegistrationRecord,
};

const mapHooksToState = (state: GlobalState) => ({
	records: state.courseRegistrationRecords.records,
	record: state.courseRegistrationRecords.record,
	loading: state.courseRegistrationRecords.loading,
	btnLoading: state.courseRegistrationRecords.btnLoading,
	selectedCourseRecord: state.courseRegistrationRecords.selectedCourseRecord,
});

type RecordsSelector = ReturnType<typeof mapHooksToState>;
type RecordsActionsMap = typeof recordsRegistrationActionsMap;

export const useRecordsRegistration = () =>
	useRedux<RecordsSelector, RecordsActionsMap>(
		mapHooksToState,
		recordsRegistrationActionsMap,
	);
