/* eslint-disable no-restricted-syntax */
import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import { nanoid } from 'nanoid';

import { ApiError, api } from 'util/api';
import { FormControl } from 'util/formSystem/formControl';
import { Validators } from 'util/formSystem/validatorFn';
import setupFormModel from 'util/formSystem/setupForm';
import {
	V1AuthVerifyUserIdentityCreateRequestPayload,
	V1AuthSendPasswordCreateRequestPayload,
} from 'util/api/swaggerApi/data-contracts';
import { getRawValue } from 'util/formSystem/formOperators';

import { ROUTE_PATHS } from 'models/sideMenu';

import history from 'store/history';

import { ToastStatus, openToast } from './toast';
import { AlertStatus, openAlert } from './alert';

import { GetState, State as GlobalState } from './reducers';

const EMAIL_URL = '<a href="mailto:Service@mfmclinic.com.tw">Service@mfmclinic.com.tw</a>';

export interface State {
	loading: boolean;
	twid: FormControl<V1AuthVerifyUserIdentityCreateRequestPayload['twid']>;
	email: string;
	phone: string;
}

export const defaultState: State = {
	loading: false,
	twid: {
		value: '',
		errors: null,
		options: {
			validators: [Validators.require, Validators.pattern(/^[A-Za-z][12]\d{8}$/)],
		},
	},
	email: '',
	phone: '',
};

export const formModelConfig = setupFormModel<State>(
	'FORGOT_PASSWORD_FORM',
	(getState: GetState) => {
		const { forgotPassword } = getState();
		return forgotPassword;
	},
);

export const submitIdentityVerification = createAction(
	'SUBMIT_IDENTITY_VERIFICATION',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const { v1AuthVerifyUserIdentityCreate } = api;
		const { forgotPassword } = getState();
		const forgotPasswordData = {
			...(getRawValue<State>(forgotPassword) as V1AuthVerifyUserIdentityCreateRequestPayload),
		};
		const identityVerificationParams = {
			twid: forgotPasswordData.twid,
		};

		try {
			const { data } = await v1AuthVerifyUserIdentityCreate(identityVerificationParams);

			history.push(`/${ROUTE_PATHS.FORGOT_PASSWORD}/${ROUTE_PATHS.GET_PASSWORD}`);
			dispatch({
				type: 'SET_EMAIL_AND_PHONE',
				payload: {
					email: data?.data?.email,
					phone: data?.data?.phone,
				},
			});
		} catch (error) {
			const apiError = (error as ApiError).error;
			const status = apiError?.status || -1;
			const message = apiError?.message || '';

			if (status === 400) {
				dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: '查無此會員' }));
			}
			if (status === 422 && message === '該會員無信箱與電話資料') {
				dispatch(
					openAlert({
						status: AlertStatus.WARNING,
						title: '您的過往資料尚未設定信箱與手機資訊',
						content: `您的過往資料尚未設定信箱與手機資訊，因此無法收取驗證碼，請聯繫客服 ${EMAIL_URL}`,
						buttonText: '確認',
					}),
				);
			} else {
				dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: message }));
			}
		}
	},
);

export const submitSendPassword = createAction(
	'SUBMIT_SEND_PASSWORD',
	(sendType: V1AuthSendPasswordCreateRequestPayload['send_type']) =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { v1AuthSendPasswordCreate } = api;
			const { forgotPassword } = getState();
			const forgotPasswordData = {
				...(getRawValue<State>(forgotPassword) as V1AuthVerifyUserIdentityCreateRequestPayload),
			};
			const sendPasswordParams = {
				twid: forgotPasswordData.twid,
				send_type: sendType,
			};

			try {
				await v1AuthSendPasswordCreate(sendPasswordParams);

				const deviceName = sendType === 'SMS' ? '手機' : '電子信箱';
				dispatch(
					openAlert({
						status: AlertStatus.SUCCESS,
						title: '密碼已發送',
						content: `密碼已發送，請至${deviceName}查詢`,
						buttonText: '前往登入頁',
						buttonHandler: () => {
							history.push('/');
							dispatch(clearForgotPasswordForm());
						},
					}),
				);
			} catch (error) {
				const apiError = (error as ApiError).error;
				const message = apiError?.message || '';

				dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: message }));
			}
		},
);

export const clearForgotPasswordForm = createAction('CLEAR_FORGOT_PASSWORD_FORM');

export const reducer = {
	forgotPassword: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			...formModelConfig.reducers,
			SET_EMAIL_AND_PHONE: (state, action) => ({
				...state,
				email: action.payload.email,
				phone: action.payload.phone,
			}),
			CLEAR_FORGOT_PASSWORD_FORM: () => ({
				...defaultState,
			}),
		},
		defaultState,
	),
};

const forgotPasswordActionsMap = {
	...formModelConfig.actions,
	submitIdentityVerification,
	submitSendPassword,
	clearForgotPasswordForm,
};

const mapHooksToState = (state: GlobalState) => ({
	forgotPassword: state.forgotPassword,
});

type ForgotPasswordSelector = ReturnType<typeof mapHooksToState>;
type ForgotPasswordActionsMap = typeof forgotPasswordActionsMap;

export const useForgotPassword = () =>
	useRedux<ForgotPasswordSelector, ForgotPasswordActionsMap>(
		mapHooksToState,
		forgotPasswordActionsMap,
	);
