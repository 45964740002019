import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';

import { GetState, State as GlobalState } from './reducers';
import { api } from 'util/api';

export interface State {
	loading: boolean;
  checkInReportList: any,
  selectCheckInItem: any,
  verifyItem: any,
}

export const defaultState: State = {
	loading: false,
  checkInReportList: [],
  selectCheckInItem: {},
  verifyItem: {
    url: ''
  }
};

export const setSelectCheckInItem = createAction(
  'SET_CHECK_IN_ITEM', (id:any) =>
		async (dispatch: Dispatch, getState: GetState) => {
      const { checkInItem: {checkInReportList} } = getState();
      return checkInReportList.filter((item:any) => item.id === parseInt(id))[0];
  }
)

export const getCheckInReportList = createAction(
  'GET_REPORT_LIST', async() => {
    const { v1CourseJoinBookingList } = api;

		try {
			const { data } = await v1CourseJoinBookingList();
      return data?.data;
				
		} catch (e) {
			console.log('getRepostList error', e);

			return [];
		}
  }
)

export const getVerifyCode = createAction(
  'GET_VERIFY_CODE', () =>
    async(dispatch: Dispatch, getState: GetState) => {
      const { checkInItem: { selectCheckInItem } } = getState();
      const { v1CourseVerifyCode } = api;

      try {
        const params = {
          id: selectCheckInItem.id,
          course_id: selectCheckInItem.courseId
        }
        const { data } = await v1CourseVerifyCode(params);

        // return data?.data;
        return {
          ...data?.data,
          ...params,
          role: 2,
          url: `role=2&id=${selectCheckInItem.id}&course_id=${selectCheckInItem.courseId}&start_time=${data?.data?.startTime}&verify_code=${data?.data?.verifyCode}`
        }
      } catch(e) {
        console.log('getVerifyCode error', e);
        return null;
      }
  }
)

export const checkInReport = createAction(
  "CHECK_IN_REPORT",(id:String) => async (dispatch: Dispatch) => {
    console.log(id)
  }
)

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	checkInItem: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
      GET_REPORT_LIST_PENDING: state => ({
        ...state,
        loading: true
      }),
      GET_REPORT_LIST_FULFILLED: (state, action: Action<any[]>) => ({
        ...state,
        loading: true,
        checkInReportList: action.payload
      }),
      CHECK_IN_REPORT_PENDING: state => ({
        ...state,
        loading: true
      }),
      CHECK_IN_REPORT_FULLFILLED: (state, action: Action<any[]>) => ({
        ...state,
        loading: false
      }),
      SET_CHECK_IN_ITEM_PENDING: state => ({
        ...state,
        loading: true
      }),
      SET_CHECK_IN_ITEM_FULFILLED: (state, action: Action<any[]>) => ({
        ...state,
        loading: false,
        selectCheckInItem: action.payload
      }),
      GET_VERIFY_CODE_PENDING: state => ({
        ...state,
        loading: true
      }),
      GET_VERIFY_CODE_FULFILLED: (state, action: Action<any[]>) => ({
        ...state,
        loading: false,
        verifyItem: action.payload
      })
		},
		defaultState,
	),
};

const checkInItemActionsMap = {
  getCheckInReportList,
  checkInReport,
  setSelectCheckInItem,
  getVerifyCode
};

const mapHooksToState = (state: GlobalState) => ({
	loading: state.checkInItem.loading,
  checkInReportList: state.checkInItem.checkInReportList,
  selectCheckInItem: state.checkInItem.selectCheckInItem,
  verifyItem: state.checkInItem.verifyItem
});

type RecordsSelector = ReturnType<typeof mapHooksToState>;
type RecordsActionsMap = typeof checkInItemActionsMap;

export const useCheckInReportList = () =>
	useRedux<RecordsSelector, RecordsActionsMap>(
		mapHooksToState,
		checkInItemActionsMap,
	);
