/* eslint-disable no-shadow */
import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { State as GlobalState } from './reducers';

export enum AlertStatus {
	SUCCESS = 'success',
	WARNING = 'warning',
	CAUTION = 'caution',
}

export interface State {
	status?: AlertStatus;
	title?: string;
	content: string;
	buttonText?: string;
	buttonHandler?: () => void;
}

export const defaultState: State = {
	status: AlertStatus.SUCCESS,
	title: '',
	content: '',
	buttonText: '',
	buttonHandler: () => { },
};

export const openAlert = createAction<State, State>('OPEN_ALERT', data => data);

export const closeAlert = createAction('CLOSE_ALERT');

export const reducer = {
	alert: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			OPEN_ALERT: (state, action: Action<State>) => ({
				...state,
				...action.payload,
			}),
			CLOSE_ALERT: () => ({
				...defaultState,
			})
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	alert: state.alert,
});

const alertActionsMap = {
	openAlert,
	closeAlert,
};

type AlertSelector = ReturnType<typeof mapHooksToState>;
type AlertActionsMap = typeof alertActionsMap;

export const useAlert = () =>
	useRedux<AlertSelector, AlertActionsMap>(mapHooksToState, alertActionsMap);
