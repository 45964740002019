/* eslint-disable no-shadow */
import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import history from 'store/history';

import {
	UserResource,
	StaffPermissionsResource,
	StaffClinicDeptResource,
} from 'util/api/swaggerApi/data-contracts';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';

import { updateAccessToken } from './auth';
import { initMenusOpenStatus, updateRouteMenus } from './sideMenu';

import { GetState, State as GlobalState } from './reducers';

export interface ExtendedStaffPermissionsResource extends StaffPermissionsResource {
	isDoctor: boolean;
	hasDropdownPermission: boolean;
	defaultSelectorDoctorValue: string;
}

// 宣告初診單 API 資料型態，因為 API 沒有產生 schema
export interface FirstDiagnosisInfo {
	firstDiagnosis: boolean;
	formUrl: string;
}

export interface ExtendedFirstDiagnosisInfo extends FirstDiagnosisInfo {
	paramsFormUrl: string;
}

export interface State {
	loading: boolean;
	userInfo: UserResource;
	staffPermissionInfo: ExtendedStaffPermissionsResource;
	firstDiagnosisInfo: ExtendedFirstDiagnosisInfo;
	device: any;
	userType: any;
	isAppStaff: boolean;
}

export const defaultState: State = {
	loading: false,
	userInfo: {} as UserResource,
	staffPermissionInfo: {} as ExtendedStaffPermissionsResource,
	firstDiagnosisInfo: {} as ExtendedFirstDiagnosisInfo,
	device: new URLSearchParams(window.location.search).get('src'),
	userType: new URLSearchParams(window.location.search).get('type'),
	isAppStaff: new URLSearchParams(window.location.search).get('type') === 'staff' && new URLSearchParams(window.location.search).get('src') ? true : false
};

export const getUserInfo = createAction('GET_USER_INFO', () => async (dispatch: Dispatch) => {
	const { v1UserList } = api;

	try {
		const { data } = await v1UserList();
		const user: UserResource = data?.data || {};
		return user as UserResource;
	} catch (error) {
		dispatch(updateAccessToken(''));
		history.push('/');
		return defaultState.userInfo;
	}
});

export const setUserInfo = createAction('SET_USER_INFO', (userInfo: UserResource) => userInfo);

export const getStaffPermissions = createAction(
	'GET_STAFF_PERMISSIONS',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const { v1StaffPermissionsList } = api;

		const {
			user: { userInfo },
		} = getState();

		// 如果是員工的話，去取得員工專區的權限
		if (!userInfo?.isStaff) {
			return defaultState.staffPermissionInfo;
		}

		try {
			const { data } = await v1StaffPermissionsList();
			const permissions: string[] = data?.data?.permissions || [];

			// 判斷是否有下拉選單權限
			const hasDropdownPermission = permissions.includes('select-filter');

			// 測試用要刪除
			// const hasDropdownPermission = false;

			// 是否為醫生
			const isDoctor =
				userInfo?.staffInfo?.clinicDept?.some((deptItem: StaffClinicDeptResource) =>
					deptItem?.dept?.includes('醫師'),
				) || false;

			let defaultSelectorDoctorValue = 'all';

			if (isDoctor) {
				defaultSelectorDoctorValue = userInfo?.name || 'all';
			}

			const returnData: ExtendedStaffPermissionsResource = {
				...data?.data,
				isDoctor,
				hasDropdownPermission,
				defaultSelectorDoctorValue,
			};

			return returnData;
		} catch (error) {
			console.log('get staff permission error', error);
			dispatch(updateAccessToken(''));
			history.push('/');
			return defaultState.staffPermissionInfo;
		}
	},
);

export const checkUserFirstDiagnosis = createAction(
	'CHECK_USER_FIRST_DIAGNOSIS',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const { v1UserCheckFirstDiagnosisList } = api;
		const { user: { device } } = getState();

		try {
			const response = await v1UserCheckFirstDiagnosisList() ;
			const data = response?.data?.data as FirstDiagnosisInfo;

			const firstDiagnosisInfo = {
				firstDiagnosis: data?.firstDiagnosis || false,
				formUrl: data?.formUrl || '',
				paramsFormUrl: '',
			};
			if (firstDiagnosisInfo.formUrl) {
				firstDiagnosisInfo.paramsFormUrl = `${firstDiagnosisInfo.formUrl}&src=${device === 'app' ? 'app' : 'web'}&env=${process.env.VENDOR_FORM_ENV}`;
			}
			return firstDiagnosisInfo as ExtendedFirstDiagnosisInfo;
		} catch (error) {
			console.error('Error on v1UserCheckFirstDiagnosisList', error);
			dispatch(updateAccessToken(''));
			history.push('/');
			return defaultState.firstDiagnosisInfo;
		}
	},
);

export const getUserPermission = createAction(
	'GET_USER_PERMISSION',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const { auth } = getState();
		if (!auth.token) return;
		await dispatch(checkUserFirstDiagnosis());
		await dispatch(getUserInfo());
		await dispatch(getStaffPermissions());
		dispatch(updateRouteMenus());
		dispatch(initMenusOpenStatus());
	},
);

export const cleanUserInfo = createAction('CLEAN_USER_INFO');

export const reducer = {
	user: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_USER_INFO_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_USER_INFO_FULFILLED: (state, action: Action<UserResource>) => ({
				...state,
				userInfo: action.payload,
				loading: false,
			}),
			SET_USER_INFO: (state, action: Action<UserResource>) => ({
				...state,
				userInfo: action.payload,
			}),

			CLEAN_USER_INFO: state => ({
				...state,
				userInfo: defaultState.userInfo,
			}),

			GET_STAFF_PERMISSIONS_FULFILLED: (
				state,
				action: Action<ExtendedStaffPermissionsResource>,
			) => ({
				...state,
				staffPermissionInfo: action.payload,
			}),

			CHECK_USER_FIRST_DIAGNOSIS_FULFILLED: (state, action: Action<ExtendedFirstDiagnosisInfo>) => ({
				...state,
				firstDiagnosisInfo: action.payload,
			}),
		},
		defaultState,
	),
};

const userActionsMap = {
	getUserInfo,
	cleanUserInfo,
	getStaffPermissions,
	checkUserFirstDiagnosis,
};

const mapHooksToState = (state: GlobalState) => ({
	userInfo: state.user.userInfo,
	staffPermissionInfo: state.user.staffPermissionInfo,
	firstDiagnosisInfo: state.user.firstDiagnosisInfo,
	device: state.user.device,
	userType: state.user.userType,
	isAppStaff: state.user.userType === 'staff' && state.user.device === 'app' ? true : false
});

type UserSelector = ReturnType<typeof mapHooksToState>;
type UserActionsMap = typeof userActionsMap;

export const useUser = () =>
	useRedux<UserSelector, UserActionsMap>(mapHooksToState, userActionsMap);
