import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';

import { api } from 'util/api';
import {
	STORAGE_KEY,
	StorageType,
	setItemToStorage,
	removeItemInStorage,
} from 'util/hook/useStorage';
import { FormControl } from 'util/formSystem/formControl';
import setupFormModel from 'util/formSystem/setupForm';
import { GetState, State as GlobalState } from './reducers';
import { updateAccessToken } from './auth';
import history from 'store/history';
import { advanceToNextStep } from 'util/routeHelper';
import { ToastStatus, openToast } from './toast';
import { nanoid } from 'nanoid';

export interface State {
	loading: boolean;
	timeList: any[];
	courseDetail: any
	selectedTime: FormControl<any>;
}

export const defaultState: State = {
	loading: false,
	timeList: [],
	courseDetail: {},
	selectedTime: {} as FormControl<any>,
};

export const formModelConfig = setupFormModel<State>('REGISTRATION_FORM', (getState: GetState) => {
	const { courseTimeRegistration } = getState();
	return courseTimeRegistration;
});

export const getTimeList = createAction(
	'GET_TIME_LIST',
	() => async (_: Dispatch, getState: GetState) => {
		const { v1CourseOptTimeList, v1StaffCourseOptTimeList } = api;
		const { courseTypeRegistration: { selectedType }, user: { isAppStaff } } = getState();

		try {
			const { data } = isAppStaff ? await v1StaffCourseOptTimeList(selectedType.value) : await v1CourseOptTimeList(selectedType.value);

			if (data?.data?.length === 0) {
				return [];
			} else {
				return data?.data?.map(item => ({
					...item,
					date: item?.startAt?.split(' ')[0],
					time: item?.startAt?.split(' ')[1],
				}));
			}
				
		} catch (e) {
			console.log('getTimeList error', e);

			return [];
		}
	},
);

export const getCourseDetail = createAction(
	'GET_COURSE_DETAIL', () => async (_: Dispatch, getState: GetState) => {
		const { v1CourseOptCourseDetail, v1StaffCourseOptCourseDetail } = api;
		const { courseTimeRegistration: { selectedTime }, user: { isAppStaff } } = getState();

		try {
			const { data } = isAppStaff ? await v1StaffCourseOptCourseDetail(selectedTime.value) : await v1CourseOptCourseDetail(selectedTime.value);

			if (data?.data?.length === 0) {
				return [];
			} else {
				return data?.data?.map(item => ({
					...item,
					date: item?.startAt?.split(' ')[0],
					time: item?.startAt?.split(' ')[1],
				}))[0];
			}
				
		} catch (e) {
			console.log('getTimeList error', e);

			return [];
		}
	},
);

export const submitOnlineRegistration = createAction(
	'SUBMIT_ONLINE_REGISTRATION',
	(submitForm:any) => async (dispatch: Dispatch, getState: GetState) => {
		const { v1CourseJoinAdd, v1StaffCourseJoinAdd } = api;
		const { courseTimeRegistration: { selectedTime }, user: { isAppStaff } } = getState();
		const { name, idCard, phone, email, note } = submitForm;

		if (name === '' && !isAppStaff) {
			dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: '請輸入姓名' }));
			return;
		}
		if (idCard === '' && !isAppStaff) {
			dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: '請輸入身分證號' }));
			return;
		}
		if (phone === '' && !isAppStaff) {
			dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: '請輸入電話號碼' }));
			return;
		}
		if (email === '' && !isAppStaff) {
			dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: '請輸入信箱' }));
			return;
		}

		let params = {};

		if (isAppStaff) {
			params = {
				courseId: selectedTime.value.id,
				note
			}
		} else {
			params = {
				courseId: selectedTime.value.id,
				name,
				identity: idCard,
				mobile: phone,
				email
			}
		}

		try {
			const { status, data } = isAppStaff ? await v1StaffCourseJoinAdd(params) : await v1CourseJoinAdd(params);

			if (status === 200) {
				advanceToNextStep({ result: "success", name });
			}
			
		} catch (error:any) {
			console.log('submitOnlineRegistration error', error);
			const { status } = error as { status: number };
			if (status === 401) {
				dispatch(updateAccessToken(''));
				history.push('/');
			} else if (status === 400) {
				dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: error.error.message }));
				// advanceToNextStep({ result: REGISTRATION_RESULT_QUERY.FAILED });
			} else if (status === 422) {
				dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: error.error.message }));
				// history.push(
				// 	`${ROUTE_PATHS.REGISTRATION_FAILURE}`,
				// );
			} else if (status === 500) {
				dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: 'error' }));
				// history.push(
				// 	`${ROUTE_PATHS.REGISTRATION_FAILURE}`,
				// );
			}
		}
		return null;
	},
);

const setSelectedOptionsToLocal = createAction('SET_SELECTED_OPTIONS_TO_LOCAL', () => (_:Dispatch, getState: any) => {
	const { courseTimeRegistration: { selectedTime } } = getState();

	setItemToStorage(STORAGE_KEY.SELECTED_TIME, selectedTime.value, StorageType.LOCAL);
});

export const clearSelectedOptions = createAction('CLEAR_SELECTED_OPTIONS', () => (dispatch: Dispatch) => {
	dispatch(formModelConfig.actions.setFormCtrlValue('selectedTime', {}));
	removeItemInStorage(STORAGE_KEY.SELECTED_TIME, StorageType.LOCAL);
});

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	courseTimeRegistration: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			...formModelConfig.reducers,
			GET_TIME_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_TIME_LIST_FULFILLED: (
				state, 
				action: Action<any[]>
			) => ({
				...state,
				timeList: action.payload,
				loading: false,
			}),
			SUBMIT_ONLINE_REGISTRATION_PENDING: state => ({
				...state,
				loading: true,
			}),
			SUBMIT_ONLINE_REGISTRATION_FULFILLED: (
				state,
			) => ({
				...state,
				loading: false,
			}),
			GET_COURSE_DETAIL_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_COURSE_DETAIL_FULFILLED: (
				state, 
				action: Action<any[]>
			) => ({
				...state,
				courseDetail: action.payload,
				loading: false,
			}),
			SET_SELECTED_WEEK: (state, action) => ({
				...state,
				selectedWeek: action.payload,
			}),
			CLEAR_SELECTED_OPTIONS: state => ({
				...state,
			}),
			
		},
		defaultState,
	),
};

const timeRegistrationActionsMap = {
	...formModelConfig.actions,
	getTimeList,
	getCourseDetail,
	setSelectedOptionsToLocal,
	clearSelectedOptions,
	submitOnlineRegistration,
};

const mapHooksToState = (state: GlobalState) => ({
	timeList: state.courseTimeRegistration.timeList,
	courseDetail: state.courseTimeRegistration.courseDetail,
	selectedTime: state.courseTimeRegistration.selectedTime,
	loading: state.courseTimeRegistration.loading,
});

type TimeRegistrationSelector = ReturnType<typeof mapHooksToState>;
type TimeRegistrationActionsMap = typeof timeRegistrationActionsMap;

export const useTimeRegistration = () =>
	useRedux<TimeRegistrationSelector, TimeRegistrationActionsMap>(
		mapHooksToState,
		timeRegistrationActionsMap,
	);
