/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { GetState, State as GlobalState } from './reducers';

export enum ToastStatus {
	SUCCESS = 'success',
	WARNING = 'warning',
}

export interface IToast {
	toastId: string;
	status: ToastStatus;
	text: string;
}

export type State = {
	toastList: IToast[];
};

export const defaultState: State = {
	toastList: [],
};

export const openToast = createAction<IToast, IToast>('OPEN_TOAST', data => data);

export const popToast = createAction('POP_TOAST', () => (_: Dispatch, getState: GetState) => {
	const {
		toast: { toastList },
	} = getState();
	return {
		toastList: toastList.slice(0, toastList.length - 1),
	};
});

export const reducer = {
	toast: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			OPEN_TOAST: (state, action: Action<IToast>) => ({
				...state,
				toastList: [...state.toastList, action.payload],
			}),
			POP_TOAST: (state, action: Action<State>) => ({
				...state,
				toastList: action.payload.toastList,
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	toastList: state.toast.toastList,
});

const toastActionsMap = {
	openToast,
	popToast,
};

type ToastSelector = ReturnType<typeof mapHooksToState>;
type ToastActionsMap = typeof toastActionsMap;

export const useToast = () =>
	useRedux<ToastSelector, ToastActionsMap>(mapHooksToState, toastActionsMap);
