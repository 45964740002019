import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import history from 'store/history';

import { api } from 'util/api';
import { AestheticMedicineAppointmentListResource } from 'util/api/swaggerApi/data-contracts';

import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';

export interface SelectorValueType {
	doctor: string;
}

export interface SelectorType {
	type: string;
	value: string;
}

export interface State {
	loading: boolean;
	allBeautyClinicList: AestheticMedicineAppointmentListResource[];
	currentFilteredList: AestheticMedicineAppointmentListResource[];
	currentBeautyListDetail: AestheticMedicineAppointmentListResource;
	selectorValue: SelectorValueType;
}

export const defaultState: State = {
	loading: false,
	allBeautyClinicList: [],
	currentFilteredList: [],
	currentBeautyListDetail: {} as AestheticMedicineAppointmentListResource,
	selectorValue: { doctor: 'all'},
};

export const getBeautyClinicList = createAction('GET_BEAUTY_CLINIC_LIST', () => async (dispatch: Dispatch) => {
	const { v1StaffAestheticMedicineAppointmentListList } = api;

	try {
		const { data } = await v1StaffAestheticMedicineAppointmentListList();

		return data?.data;
	} catch (e) {
		console.log('beauty list error', e);
		const { status } = e as { status: number };
		if (status === 401) {
			dispatch(updateAccessToken(''));
			history.push('/');
		}
		return [];
	}
});

export const filterBeautyClinicList = createAction(
	'FILTER_BEAUTY_CLINIC_LIST',
	(u: string, doctor: string) => (_: Dispatch, getState: GetState) => {
		// u 為了符合 initListAndDoctorValue 規範，但實際上沒有用到
		const { beautyClinicList: { allBeautyClinicList }} = getState();

		if (doctor === 'all') {
			return allBeautyClinicList;
		}
		if (doctor === '') {
			return [];
		}
		
		const filteredList = allBeautyClinicList.filter(item => item.visitingDoctor === doctor);

		return filteredList;
	},
);

export const setCurrentBeautyListDetail = createAction(
	'SET_CURRENT_BEAUTY_LIST_DETAIL',
	(detail: AestheticMedicineAppointmentListResource) => detail,
);

export const setBeautySelectorValue = createAction(
	'SET_BEAUTY_SELECTOR_VALUE',
	(type: string, value: string) => ({type, value}),
);

export const cleanBeautySelectorValue = createAction('CLEAN_BEAUTY_SELECTOR_VALUE');

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	beautyClinicList: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_BEAUTY_CLINIC_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_BEAUTY_CLINIC_LIST_FULFILLED: (
				state,
				action: Action<AestheticMedicineAppointmentListResource[]>,
			) => ({
				...state,
				allBeautyClinicList: [...action.payload],
				loading: false,
			}),
			FILTER_BEAUTY_CLINIC_LIST: (
				state,
				action: Action<AestheticMedicineAppointmentListResource[]>,
			) => ({
				...state,
				currentFilteredList: [...action.payload],
			}),
			SET_BEAUTY_SELECTOR_VALUE: (state, action: Action<SelectorType>) => ({
				...state,
				selectorValue: {
					...state.selectorValue,
					[action.payload.type]: action.payload.value,
				},
			}),
			SET_CURRENT_BEAUTY_LIST_DETAIL: (state, action: Action<AestheticMedicineAppointmentListResource>) => ({
				...state,
				currentBeautyListDetail: action.payload,
				loading: false,
			}),
			CLEAN_BEAUTY_SELECTOR_VALUE: state => ({
				...state,
				selectorValue: {
					doctor: 'all'
				},
			}),
		},
		defaultState,
	),
};

const beautyListActionsMap = {
	getBeautyClinicList,
	setBeautySelectorValue,
	filterBeautyClinicList,
	setCurrentBeautyListDetail,
	cleanBeautySelectorValue,
};

const mapHooksToState = (state: GlobalState) => ({
	allBeautyClinicList: state.beautyClinicList.allBeautyClinicList,
	selectorDoctorValue: state.beautyClinicList.selectorValue.doctor,
	currentFilteredList: state.beautyClinicList.currentFilteredList,
	currentBeautyListDetail: state.beautyClinicList.currentBeautyListDetail,
});

type BeautyListSelector = ReturnType<typeof mapHooksToState>;
type BeautyListActionsMap = typeof beautyListActionsMap;

export const useBeautyClinicList = () =>
	useRedux<BeautyListSelector, BeautyListActionsMap>(
		mapHooksToState,
		beautyListActionsMap,
	);