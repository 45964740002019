import { CustomRoute } from 'util/hook/useRouter';

import EyeCheckImageRoute from './EyeCheckImage';
import NearsightedTrackMedicineRoute from './NearsightedTrackMedicine';
import WeaknessTrainTrackRoute from './WeaknessTrainTrack'
import NearsightedTrackRoute from './NearsightedTrack';
import GlaucomaTrackRoute from './GlaucomaTrack';
import OptomeryReportRoute from './OptomeryReport';


const routes: CustomRoute = {
	path: '/eye-report',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [EyeCheckImageRoute, NearsightedTrackMedicineRoute, WeaknessTrainTrackRoute, NearsightedTrackRoute, GlaucomaTrackRoute, OptomeryReportRoute],
};

export default routes;
