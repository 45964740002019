import qs from 'qs';
import history from 'store/history';
import { ROUTE_PATHS } from 'models/sideMenu';

export const NEED_TOKEN_PAGES = [
	ROUTE_PATHS.IMAGE_REPORT,
	ROUTE_PATHS.HEALTH_REPORT,
	ROUTE_PATHS.USER_INFO,
	ROUTE_PATHS.STAFF,
	// ROUTE_PATHS.APPOINTMENTS,
	ROUTE_PATHS.WELCOME,
	ROUTE_PATHS.EYE_REPORT,
	ROUTE_PATHS.CHECK_IN_ITEM,
	// ROUTE_PATHS.DIAGNOSIS_INFO,
	// ROUTE_PATHS.COURSE_RESERVATION,
];

export const isNeedTokenPage = (pathname: string) =>
	NEED_TOKEN_PAGES.some(path => pathname.toLowerCase().includes(path));

export const isAppointmentPage = (pathname: string) =>
	pathname.includes("appointments");

export const isNotNeedTokenPage = (pathname: string) =>
	pathname.includes("medical-progress") || pathname.includes("course-reservation") || pathname.includes("appointments") || pathname.includes("doctor-registration") || pathname.includes("diagnosis-info");

export const advanceToNextStep = (queryParams = {}) => {
	const currentStep =
			parseInt(
				qs.parse(history.location.search as string, { ignoreQueryPrefix: true }).step as string,
				10,
			) || 1;
	const step = currentStep + 1;
	history.push({
		pathname: history.location.pathname,
		search: qs.stringify({ step: step.toString(), ...queryParams }, { addQueryPrefix: true }),
	});
};
export const getRouteQuery = (param: string) => {
	const urlParams = new URLSearchParams(window.location.search);
	const result = urlParams.get(param);

	return result;
};
