import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';
import RegistrationRoute from './Registration';


const routes: CustomRoute = {
	path: '/appointments',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'appointments' */ './component')],
			render: ([Appointments]) => <Appointments />,
			onEnter: async ({ next }) => {
				console.log('on Enter appointments');

				console.log('on Enter appointments / end');
				const children = await next();
				return children;
			},
		},
		RegistrationRoute,
	],
};

export default routes;
