import React, { useState, useRef, useEffect } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import classNames from 'classnames';

import styles from './index.module.css';

export interface StyleMap {
	dropdown: string;
	dropdownTrigger: string;
	dropdownPanel: string;
}

export interface MainComponentProperty {
	isOpen: boolean;
}

export interface PanelComponentProperty {
	onClosePanel: () => void;
}

interface DropdownProperty {
	defaultOpen?: boolean;
	renderMainComponent: (props: MainComponentProperty) => JSX.Element;
	renderPanelComponent: (props: PanelComponentProperty) => JSX.Element;
	open?: boolean;
	styleMap?: Partial<StyleMap>;
	autoClose?: boolean;
	disabled?: boolean;
}

const Dropdown: React.FC<DropdownProperty> = ({
	defaultOpen = false,
	renderMainComponent,
	renderPanelComponent,
	open = false,
	styleMap = {},
	autoClose = true,
	disabled = false,
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(defaultOpen);
	const mainRef = useRef<HTMLDivElement>(null);
	const panelRef = useRef(null);

	// 點擊 dropdown 外部時關閉 dropdown
	useOnClickOutside(panelRef, (e: Event) => {
		const target = e.target as HTMLElement;
		if (!autoClose) {
			return;
		}
		if (target !== mainRef.current && !mainRef.current?.contains(target)) {
			setIsDropdownOpen(false);
		}
	});

	const handleToggle = () => {
		if (disabled) {
			return;
		}
		setIsDropdownOpen(!isDropdownOpen);
	};

	useEffect(() => {
		setIsDropdownOpen(open);
	}, [open]);

	return (
		<div className={classNames(styles.dropdown, styleMap.dropdown)}>
			<div
				role="button"
				tabIndex={0}
				ref={mainRef}
				className={classNames(styles.dropdownTrigger, styleMap.dropdownTrigger)}
				onKeyDown={() => {}}
				onClick={handleToggle}
			>
				{renderMainComponent({ isOpen: isDropdownOpen })}
			</div>
			{isDropdownOpen && (
				<div ref={panelRef} className={classNames(styles.dropdownPanel, styleMap.dropdownPanel)}>
					{renderPanelComponent({ onClosePanel: () => setIsDropdownOpen(false) })}
				</div>
			)}
		</div>
	);
};

export default Dropdown;
