import { combineReducers } from 'redux';

import * as routing from './routing';
import * as members from './member';
import * as blogs from './blog';
import * as banners from './banner';
import * as loginForm from './loginForm';
import * as imageReports from './imageReport';
import * as healthReports from './healthReport';
import * as auth from './auth';
import * as modal from './modal';
import * as toast from './toast';
import * as user from './user';
import * as sideMenu from './sideMenu';
import * as surgeryList from './surgeryList';
import * as inpatientList from './inpatientList';
import * as newbornList from './newbornList';
import * as beautyClinicList from './beautyClinic';
import * as signUp from './signUp';
import * as forgotPassword from './forgotPassword';
import * as alert from './alert';
import * as editUserInfoForm from './editUserInfoForm';
import * as outpatientRegistration from './outpatientRegistration';
import * as departmentRegistration from './departmentRegistration';
import * as recordsRegistration from './recordsRegistration';
import * as eyeCheckImageReports from './eyeCheckImageReport';
import * as nearsightedTrackMedicineReports from './nearsightedTrackMedicineReport';
import * as weaknessTrainTrackReports from './weaknessTrainTrackReport';
import * as medicalProgressList from './medicalProgress';
import * as courseTypeRegistration from './courseTypeRegistration';
import * as courseTimeRegistration from './courseTimeRegistration';
import * as courseRegistrationsSearch from './courseRegistrationsSearch';
import * as courseRegistrationRecords from './courseRegistrationRecords';
import * as nearsightedTrackReports from './nearsightedTrackReport';
import * as glaucomaTrackReports from './glaucomaTrackReport';
import * as optomeryListReports from './optomeryReport';
import * as doctorDepartmentRegistration from './doctorDepartmentRegistration';
import * as doctorOutpatientRegistration from './doctorOutpatientRegistration';
import * as checkInItem from './checkInItem';


// For Global State interface
export interface State {
	blogs: blogs.State;
	banners: banners.State;
	members: members.State;
	routing: routing.State;
	loginForm: loginForm.State;
	imageReports: imageReports.State;
	healthReports: healthReports.State;
	surgeryList: surgeryList.State;
	auth: auth.State;
	modal: modal.State;
	toast: toast.State;
	user: user.State;
	sideMenu: sideMenu.State;
	inpatientList: inpatientList.State;
	newbornList: newbornList.State;
	beautyClinicList: beautyClinicList.State;
	signUp: signUp.State;
	forgotPassword: forgotPassword.State;
	alert: alert.State;
	editUserInfoForm: editUserInfoForm.State;
	outpatientRegistration: outpatientRegistration.State;
	departmentRegistration: departmentRegistration.State;
	recordsRegistration: recordsRegistration.State;
	eyeCheckImageReports: eyeCheckImageReports.State;
	nearsightedTrackMedicineReports: nearsightedTrackMedicineReports.State;
	weaknessTrainTrackReports: weaknessTrainTrackReports.State;
	medicalProgressList: medicalProgressList.State;
	courseTypeRegistration: courseTypeRegistration.State;
	courseTimeRegistration: courseTimeRegistration.State;
	courseRegistrationsSearch: courseRegistrationsSearch.State;
	courseRegistrationRecords: courseRegistrationRecords.State;
	nearsightedTrackReports: nearsightedTrackReports.State;
	glaucomaTrackReports: glaucomaTrackReports.State;
	optomeryListReports: optomeryListReports.State;
	doctorDepartmentRegistration: doctorDepartmentRegistration.State;
	doctorOutpatientRegistration: doctorOutpatientRegistration.State;
	checkInItem: checkInItem.State;
}

export type GetState = () => State;

export const defaultState: State = {
	blogs: blogs.defaultState,
	banners: banners.defaultState,
	members: members.defaultState,
	routing: routing.defaultState,
	loginForm: loginForm.defaultState,
	imageReports: imageReports.defaultState,
	healthReports: healthReports.defaultState,
	surgeryList: surgeryList.defaultState,
	auth: auth.defaultState,
	modal: modal.defaultState,
	toast: toast.defaultState,
	user: user.defaultState,
	sideMenu: sideMenu.defaultState,
	inpatientList: inpatientList.defaultState,
	newbornList: newbornList.defaultState,
	beautyClinicList: beautyClinicList.defaultState,
	signUp: signUp.defaultState,
	forgotPassword: forgotPassword.defaultState,
	alert: alert.defaultState,
	editUserInfoForm: editUserInfoForm.defaultState,
	outpatientRegistration: outpatientRegistration.defaultState,
	departmentRegistration: departmentRegistration.defaultState,
	recordsRegistration: recordsRegistration.defaultState,
	eyeCheckImageReports: eyeCheckImageReports.defaultState,
	nearsightedTrackMedicineReports: nearsightedTrackMedicineReports.defaultState,
	weaknessTrainTrackReports: weaknessTrainTrackReports.defaultState,
	medicalProgressList: medicalProgressList.defaultState,
	courseTypeRegistration: courseTypeRegistration.defaultState,
	courseTimeRegistration: courseTimeRegistration.defaultState,
	courseRegistrationsSearch: courseRegistrationsSearch.defaultState,
	courseRegistrationRecords: courseRegistrationRecords.defaultState,
	nearsightedTrackReports: nearsightedTrackReports.defaultState,
	glaucomaTrackReports: glaucomaTrackReports.defaultState,
	optomeryListReports: optomeryListReports.defaultState,
	doctorDepartmentRegistration: doctorDepartmentRegistration.defaultState,
	doctorOutpatientRegistration: doctorOutpatientRegistration.defaultState,
	checkInItem: checkInItem.defaultState,
};

const reducers = combineReducers<State>({
	...routing.reducer,
	...members.reducer,
	...blogs.reducer,
	...banners.reducer,
	...loginForm.reducer,
	...imageReports.reducer,
	...healthReports.reducer,
	...surgeryList.reducer,
	...auth.reducer,
	...modal.reducer,
	...toast.reducer,
	...user.reducer,
	...sideMenu.reducer,
	...inpatientList.reducer,
	...newbornList.reducer,
	...beautyClinicList.reducer,
	...signUp.reducer,
	...forgotPassword.reducer,
	...alert.reducer,
	...editUserInfoForm.reducer,
	...outpatientRegistration.reducer,
	...departmentRegistration.reducer,
	...recordsRegistration.reducer,
	...eyeCheckImageReports.reducer,
	...nearsightedTrackMedicineReports.reducer,
	...weaknessTrainTrackReports.reducer,
	...medicalProgressList.reducer,
	...courseTypeRegistration.reducer,
	...courseTimeRegistration.reducer,
	...courseRegistrationsSearch.reducer,
	...courseRegistrationRecords.reducer,
	...nearsightedTrackReports.reducer,
	...glaucomaTrackReports.reducer,
	...optomeryListReports.reducer,
	...doctorDepartmentRegistration.reducer,
	...doctorOutpatientRegistration.reducer,
	...checkInItem.reducer
});

export default reducers;
