import React, { MouseEvent } from 'react';

import { useHistory } from 'models/routing';
import { History } from 'history';

export type LinkType = 'internal' | 'external';

const onClickHandler =
	(
		callback: (e: MouseEvent<HTMLAnchorElement>) => void,
		history: History,
		type: LinkType,
		to?: string,
	) =>
		(e: MouseEvent<HTMLAnchorElement>): void => {
			e.preventDefault();
			if (type === 'internal' && to) {
				history.push({ pathname: e.currentTarget.pathname, search: to?.split('?')[1] });
				window.scrollTo({ top: 0 });
			}
			if (type === 'external' && to) {
				if (to.includes('http://') || to.includes('https://')) {
					window.open(to, '_blank');
				} else {
					window.open(`https://${to}`, '_blank');
				}
			}
			callback(e);
		};

interface LinkProperty {
	className?: string;
	to?: string;
	type?: LinkType;
	onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
	children: React.ReactNode;
}

const Link = ({ className, to, type = 'internal', onClick = () => {}, children }: LinkProperty) => {
	const history = useHistory();

	return (
		<a
			className={className}
			href={to}
			role="button"
			tabIndex={0}
			onClick={onClickHandler(onClick, history, type, to)}
		>
			{children}
		</a>
	);
};

export default Link;
