import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';


const routes: CustomRoute = {
	path: '/:id',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'registrationRecord' */ './component')],
			render: ([RegistrationRecord]) => <RegistrationRecord />,
			onEnter: async ({ next }) => {
				console.log('on Enter registrations single record');

				const children = await next();
				return children;
			},
		},
		{
			path: '/cancel',
			components: () => [
				import(/* webpackChunkName: 'registrationCancel' */ 'layouts/RegistrationCancel'),
			],
			render: ([RegistrationCancel]) => <RegistrationCancel />,
			onEnter: async ({ next }) => {
				console.log('on Enter Registration Cancel start');
				const children = await next();
				console.log('on Enter Registration Cancel end');
				return children;
			},
		},
	],
};
export default routes;
