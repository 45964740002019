import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/members',
	components: () => [import(/* webpackChunkName: 'members' */ './component')],
	render: ([Members]) => <Members />,
	onEnter: async () => {
		console.log('on Enter member');
		console.log('on Enter member / end');
		await sleep(5000);
	},
};
export default routes;
