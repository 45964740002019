import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { getBranchList } from 'models/departmentRegistration';
import {
	getAllNewbornList,
	filterNewbornList,
	setNewbornSelectorValue,
	cleanNewbornSelectorValue,
	cleanNewbornInspectionReport,
	initNewBornDoctorValue,
} from 'models/newbornList';
import { getDoctorList, initListAndDoctorValue } from 'models/inpatientList';
import { ROUTE_PATHS } from 'models/sideMenu';
import { checkAuthGuard } from 'models/auth';

import NewbornListInner from './Inner';

const routes: CustomRoute = {
	path: '/newborn-list',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'newbornList' */ './component')],
			render: ([NewbornList]) => <NewbornList />,
			onEnter: async ({ next, store }) => {
				await store.dispatch(getBranchList());
				// await store.dispatch(cleanNewbornSelectorValue());
				await store.dispatch(cleanNewbornInspectionReport());
				const allowEnter = checkAuthGuard(store, `/${ROUTE_PATHS.NEWBORN_LIST}`);

				if (allowEnter) {
					const {
						user: { staffPermissionInfo },
					} = store.getState();
					if (staffPermissionInfo.isDoctor || staffPermissionInfo.hasDropdownPermission) {
						await store.dispatch(getAllNewbornList());
						await store.dispatch(getDoctorList());
						await store.dispatch(initNewBornDoctorValue());
						// await store.dispatch(
						// 	initListAndDoctorValue(setNewbornSelectorValue, filterNewbornList),
						// );
					}
				}

				const children = await next();
				return children;
			},
		},
		NewbornListInner,
	],
};
export default routes;
