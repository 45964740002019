import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';

import { api } from 'util/api';
import { GetState, State as GlobalState } from './reducers';
import { updateAccessToken } from './auth';
import history from 'store/history';
import { ToastStatus, openToast } from './toast';
import { nanoid } from 'nanoid';
import { FormControl } from 'util/formSystem/formControl';
import { Validators } from 'util/formSystem/validatorFn';
import setupFormModel from 'util/formSystem/setupForm';

export interface State {
	loading: boolean;
	btnLoading: boolean;
	idCard: FormControl<string>;
	captcha: FormControl<string>;
	key: any;
	getCaptcha: any;
	searchInfo: any;
}

export const defaultState: State = {
	loading: false,
	btnLoading: false,
	idCard: {
		value: '',
		errors: null,
		options: {
			validators: [Validators.require, Validators.pattern(/^[A-Za-z0-9]{10}$/)],
		},
	},
	key: '',
	captcha: {
		value: '',
		errors: null,
		// options: {
		// 	validators: [Validators.require, Validators.pattern(/^[A-Za-z0-9]{10}$/)],
		// },
	},
	getCaptcha: '',
	searchInfo: null
};

export const formModelConfig = setupFormModel<State>('EDIT_ID_CARD_FORM', (getState: GetState) => {
	const { courseRegistrationsSearch } = getState();
	return courseRegistrationsSearch;
});

export const clearSearchInfoForm = createAction(
	'CLEAR_INFO_FORM',
	() => async (_dispatch: Dispatch, getState: GetState) => {
		
	}
);

export const getCourseCaptcha = createAction(
	'GET_COURSE_CAPTCHA', () => async (_: Dispatch, getState: GetState) => {
		const { v1CourseCaptcha } = api;

		try {
			const { img, key } = await v1CourseCaptcha();

			return {img, key};
				
		} catch (e) {
			console.log('getTimeList error', e);

			return [];
		}
	},
);

export const submitSearch = createAction(
	'SEARCH_REGISTRATION',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const { v1CourseCheck } = api;
    const { courseRegistrationsSearch } = getState();
		if (courseRegistrationsSearch.idCard.value === '') {
			dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: '請輸入身分證號' }));
			return;
		}
		if (courseRegistrationsSearch.captcha.value === '') {
			dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: '請輸入驗證碼' }));
			return;
		}

		const params = {
			identity: courseRegistrationsSearch.idCard.value,
			captcha: courseRegistrationsSearch.captcha.value,
			key: courseRegistrationsSearch.getCaptcha.key
		};
		try {
			const { status, data } = await v1CourseCheck(params);

			if (status === 200) {
				history.push('/course-reservation/registrations/records');
				return data?.data[0];
			}
		} catch (error:any) {
			console.log('submitOnlineRegistration error', error);
			const { status } = error as { status: number };
			if (status === 401) {
				dispatch(updateAccessToken(''));
				history.push('/');
			} else if (status === 400) {
				dispatch(getCourseCaptcha());
				// getCourseCaptcha();
				dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: error.error.message }));
				// advanceToNextStep({ result: REGISTRATION_RESULT_QUERY.FAILED });
			} else if (status === 500) {
				dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: error.error.message }));
				// history.push(
				// 	`${ROUTE_PATHS.REGISTRATION_FAILURE}`,
				// );
			}
		}
		return null;
	},
);

export const reducer = {
  courseRegistrationsSearch: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
  {
    ...formModelConfig.reducers,
    SEARCH_REGISTRATION_PENDING: state => ({
      ...state,
      btnLoading: true,
    }),
    SEARCH_REGISTRATION_FULFILLED: (state, action: Action<any[]>) => ({
      ...state,
      btnLoading: false,
			searchInfo: action.payload
    }),
		GET_COURSE_CAPTCHA_PENDING: state => ({
      ...state,
      loading: true,
    }),
		GET_COURSE_CAPTCHA_FULFILLED: (
			state, 
			action: Action<any[]>
		) => ({
			...state,
			getCaptcha: action.payload,
			loading: false,
		}),
    CLEAR_INFO_FORM_FULFILLED: () => ({
      ...defaultState,
    }),
  },
  defaultState,
  ),
};

const searchRegistrationActionsMap = {
	...formModelConfig.actions,
	submitSearch,
  clearSearchInfoForm,
	getCourseCaptcha
};

const mapHooksToState = (state: GlobalState) => ({
	courseRegistrationsSearch: state.courseRegistrationsSearch,
	getCaptcha: state.courseRegistrationsSearch.getCaptcha,
	loading: state.courseRegistrationsSearch.loading,
	btnLoading: state.courseRegistrationsSearch.btnLoading,
	searchInfo: state.courseRegistrationsSearch.searchInfo
});

type searchRegistrationSelector = ReturnType<typeof mapHooksToState>;
type searchRegistrationActionsMap = typeof searchRegistrationActionsMap;

export const useSearchRegistration = () =>
	useRedux<searchRegistrationSelector, searchRegistrationActionsMap>(
		mapHooksToState,
		searchRegistrationActionsMap,
	);
