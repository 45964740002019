/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';

import { AlertStatus, useAlert } from 'models/alert';

import CheckIcon from 'images/icon/check.inline.svg';
import WarningIcon from 'images/icon/warning.inline.svg';
import CautionIcon from 'images/icon/caution.inline.svg';

import styles from './index.module.css';

const Alert: React.FC = () => {
	const [{ alert }, { closeAlert }] = useAlert();

	if (!alert.content) {
		return null;
	}

	const AlertIcon = {
		success: <CheckIcon />,
		warning: <WarningIcon />,
		caution: <CautionIcon />,
	};

	const clickHandler = () => {
		if (alert.buttonHandler) {
			alert.buttonHandler();
		}
		closeAlert();
	};

	return (
		<div
			className={classNames(styles.alert, {
				[styles.success]: alert.status === AlertStatus.SUCCESS,
				[styles.warning]: alert.status === AlertStatus.WARNING,
				[styles.caution]: alert.status === AlertStatus.CAUTION,
			})}
		>
			<div
				className={classNames({
					[styles.successIcon]: alert.status === AlertStatus.SUCCESS,
					[styles.warningIcon]: alert.status === AlertStatus.WARNING,
					[styles.cautionIcon]: alert.status === AlertStatus.CAUTION,
				})}
			>
				{AlertIcon[alert.status as AlertStatus]}
			</div>
			<div className={styles.mainArea}>
				{alert.title && <h3 className={styles.title}>{alert.title}</h3>}
				<p className={styles.content}>{parse(alert.content)}</p>
				{alert.buttonText && alert.buttonHandler && (
					<div className={styles.buttonWrapper}>
						<button type="button" onClick={clickHandler}>
							{alert.buttonText}
						</button>
					</div>
				)}
			</div>
			<div>
				<button type="button" className={styles.closeButton} onClick={closeAlert} />
			</div>
		</div>
	);
};

export default Alert;
