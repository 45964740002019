import React, { useEffect } from 'react';
import classNames from 'classnames';

import { ToastStatus, useToast } from 'models/toast';

import InfoIcon from 'images/icon/info-dark.inline.svg';
import CheckIcon from 'images/icon/check.inline.svg';
import WarningIcon from 'images/icon/warning.inline.svg';

import styles from './index.module.css';

const Toast: React.FC = () => {
	const [{ toastList }, { popToast }] = useToast();

	const ToastIcon = {
		success: <CheckIcon />,
		warning: <WarningIcon />,
		info: <InfoIcon />,
	};

	useEffect(() => {
		let timer: NodeJS.Timeout | null = null;

		if (toastList.length) {
			timer = setTimeout(() => {
				popToast();
			}, 4000);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [toastList]);

	if (toastList.length <= 0) {
		return null;
	}

	return (
		<div className={styles.toastWrapper}>
			{toastList.map(toast => (
				<div className={styles.toast} key={toast.toastId}>
					<div
						className={classNames(styles.icon, {
							[styles.successIcon]: toast.status === ToastStatus.SUCCESS,
							[styles.warningIcon]: toast.status === ToastStatus.WARNING,
						})}
					>
						{ToastIcon[toast.status]}
					</div>
					<p>{toast.text}</p>
				</div>
			))}
		</div>
	);
};

export default Toast;
