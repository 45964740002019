import { useEffect, useState } from 'react';

export type Media = 'desktop' | 'mobile';

const useMedia = () => {
	const getMedia = () => {
		const width = window.innerWidth;
		const desktopMinWidth = 1200;
		return width < desktopMinWidth ? 'mobile' : 'desktop';
	};
	const [media, setMedia] = useState<Media>(() => {
		const m = getMedia();
		return m;
	});

	const handleResize = () => {
		const currentMedia = getMedia();
		setMedia(currentMedia);
	};

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return media;
};

export default useMedia;
