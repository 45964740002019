import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import history from 'store/history';

import { api } from 'util/api';
import {
	STORAGE_KEY,
	StorageType,
	setItemToStorage,
	removeItemInStorage,
} from 'util/hook/useStorage';
import { HospitalDepartmentResource, HospitalBranchResource, V1HospitalBrancheDepartmentRefListRequestParams, HospitalBrancheDepartmentRefResource, V1UserSingleFirstDiagnosisListRequestParams } from 'util/api/swaggerApi/data-contracts';
import { FormControl } from 'util/formSystem/formControl';
import setupFormModel from 'util/formSystem/setupForm';
import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';

export interface ExtendedHospitalDepartmentResource extends HospitalDepartmentResource {
    isAvailable: boolean;
};

export interface ExtendedHospitalDoctorResource extends HospitalBranchResource {
		isAvailable: boolean;
		clinicsNameArray?: string[];
		nameEn: string;
};

export interface SingleFirstDiagnosisInfo {
	success: boolean;
	message: string;
	data: string;
	formUrl?: string;
	paramsFormUrl?: string;
}

export interface State {
	loading: boolean;
	departmentList: ExtendedHospitalDepartmentResource[];
	doctorList: ExtendedHospitalDoctorResource[];
	selectedDepartment: FormControl<ExtendedHospitalDepartmentResource>;
	selectedBranch: FormControl<ExtendedHospitalDoctorResource>;
	clinicsCodeList: string[];
	singleFirstDiagnosis: SingleFirstDiagnosisInfo;
	searchDoctorList: ExtendedHospitalDoctorResource[];
}

export const defaultState: State = {
	loading: false,
	departmentList: [],
	doctorList: [],
	selectedDepartment: {} as FormControl<ExtendedHospitalDepartmentResource>,
	selectedBranch: {} as FormControl<ExtendedHospitalDoctorResource>,
	clinicsCodeList: [],
	singleFirstDiagnosis: {} as SingleFirstDiagnosisInfo,
	searchDoctorList: []
};

export const formModelConfig = setupFormModel<State>('REGISTRATION_FORM', (getState: GetState) => {
	const { doctorDepartmentRegistration } = getState();
	return doctorDepartmentRegistration;
});

export const getDepartmentList = createAction(
	'GET_DEPARTMENT_LIST', async() => {
		const { v1HospitalDepartmentListList } = api;

		try {
			const { data } = await v1HospitalDepartmentListList();

			const extendedData = data?.data?.map((department:HospitalDepartmentResource) => ({
				...department,
				isAvailable: true, 
			}));
			return extendedData;
				
		} catch (e) {
			console.log('getDepartmentList error', e);

			return [];
		}
	},
);

const updateDepartmentList = createAction('UPDATE_DEPARTMENT_LIST', (departmentList: ExtendedHospitalDepartmentResource[]) => departmentList);


export const getDoctorList = createAction(
	'GET_DOCTOR_LISTS', async(id:any) => {
		const { v1HospitalDoctorList } = api;

		try {
			const { data } = await v1HospitalDoctorList(id);
			const clinicsCodeList: string[] = [];
			const extendedData = data?.data?.map((item:HospitalBranchResource) => {
				return {
					...item,
					isAvailable: true,
				}
			});
			return { branchData: extendedData, clinicsCodeList };
		} catch (e) {
			console.log('getDoctorList error', e);

			return { branchData: [], clinicsCodeList: []};
		}
	},
);

const updateDoctorList = createAction('UPDATE_DOCTOR_LIST', (doctorList: ExtendedHospitalDoctorResource[]) => doctorList);

const getDoctorDepartmentRef = createAction(
	'GET_BRANCH_DEPARTMENT_REF', (type: 'BRANCH' | 'DEPARTMENT', id: number) =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { doctorDepartmentRegistration: { doctorList, departmentList } } = getState();
			const { v1HospitalBrancheDepartmentRefList } = api;

			const params: V1HospitalBrancheDepartmentRefListRequestParams = {
				type,
				...(type === 'BRANCH' ? { branch_id: id } : { department_id: id }),
			};

			try {
				const { data } = await v1HospitalBrancheDepartmentRefList(params);
				if (type === 'DEPARTMENT') {
					const availableBranchIds = data?.data?.map((item: HospitalBrancheDepartmentRefResource) => item.id) || [];

					const newBranchList = doctorList.map(branch => {
						const isAvailable = availableBranchIds.includes(branch.id);
						return {
							...branch,
							isAvailable,
						};
					})
					dispatch(updateDoctorList(newBranchList));
				} else if ( type === 'BRANCH' && data?.data) {
					const availableDepartmentIds = data?.data[0].departments?.map((item: HospitalDepartmentResource) => item.id) || [];

					const newDepartmentList = departmentList.map(department => {
						const isAvailable = availableDepartmentIds.includes(department.id);
						return {
							...department,
							isAvailable,
						};
					})
					dispatch(updateDepartmentList(newDepartmentList));
				}

				return [];
			} catch (e) {
				console.log('getDoctorDepartmentRef error', e);
				return [];
			}
		},
);

const setSelectedOptionsToLocal = createAction('SET_SELECTED_OPTIONS_TO_LOCAL', () => (_:Dispatch, getState: GetState) => {
	const { doctorDepartmentRegistration: { selectedDepartment, selectedBranch } } = getState();

	setItemToStorage(STORAGE_KEY.SELECTED_DEPARTMENT, selectedDepartment.value, StorageType.LOCAL);
	setItemToStorage(STORAGE_KEY.SELECTED_DOCTOR, selectedBranch.value, StorageType.LOCAL);
});

export const clearSelectedOptions = createAction('CLEAR_SELECTED_OPTIONS', () => (dispatch: Dispatch) => {
	dispatch(formModelConfig.actions.setFormCtrlValue('selectedDepartment', {}));
	dispatch(formModelConfig.actions.setFormCtrlValue('selectedBranch', {}));
	removeItemInStorage(STORAGE_KEY.SELECTED_DEPARTMENT, StorageType.LOCAL);
	removeItemInStorage(STORAGE_KEY.SELECTED_DOCTOR, StorageType.LOCAL);
});

export const clearSelectedDoctor = createAction('CLEAR_SELECTED_DOCTOR', () => (dispatch: Dispatch) => {
	dispatch(formModelConfig.actions.setFormCtrlValue('selectedBranch', {}));
	removeItemInStorage(STORAGE_KEY.SELECTED_DOCTOR, StorageType.LOCAL);
});

export const clearSearchDoctorList = createAction('CLEAR_SEARCH_DOCTOR_LIST', () => (dispatch: Dispatch) => {
	// dispatch(formModelConfig.actions.setFormCtrlValue('searchDoctorList', []));
});

export const checkSingleFirstDiagnosis = createAction('CHECK_SINGLE_FIRST_DIAGNOSIS',(deptCode: string) => async (dispatch: Dispatch) => {
	const { v1UserSingleFirstDiagnosisList } = api;
	try {
		const response = await v1UserSingleFirstDiagnosisList({ dept: deptCode} as V1UserSingleFirstDiagnosisListRequestParams);
		const data = response?.data?.data as SingleFirstDiagnosisInfo;

		if (data?.data === 'N') {
			const paramsFormUrl = `${data.formUrl}?src=web&env=${process.env.VENDOR_FORM_ENV}`;
			data.paramsFormUrl = paramsFormUrl;
		}
		return data;

	} catch(err) {
		console.log('checkSingleFirstDiagnosis api err', err);
		const { status } = err as { status: number };
		if (status === 401) {
			dispatch(updateAccessToken(''));
			history.push('/');
		}
		return {};
	}
});

export const filterDoctorList = createAction(
	'FILTER_DOCTOR_LIST',(doctorName: string) => 
		async (dispatch: Dispatch, getState: GetState) => {
			const { doctorDepartmentRegistration: { doctorList } } = getState();
			const newDoctorArr = [...doctorList];
			if (doctorName === '') {
				return newDoctorArr;
			}
			return newDoctorArr.filter(item => item.name?.includes(doctorName));
});

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	doctorDepartmentRegistration: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			...formModelConfig.reducers,
			GET_DEPARTMENT_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_DOCTOR_LISTS_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_DEPARTMENT_LIST_FULFILLED: (
				state, 
				action: Action<ExtendedHospitalDepartmentResource[]>
			) => ({
				...state,
				departmentList: action.payload,
				loading: false,
			}),
			GET_DOCTOR_LISTS_FULFILLED: (
				state, 
				action: Action<{ branchData: ExtendedHospitalDoctorResource[], clinicsCodeList: string[] }>
			) => ({
				...state,
				doctorList: action.payload.branchData,
				searchDoctorList: action.payload.branchData,
				clinicsCodeList: action.payload.clinicsCodeList,
				loading: false,
			}),
			UPDATE_DEPARTMENT_LIST: (state, action: Action<ExtendedHospitalDepartmentResource[]>) => ({
				...state,
				departmentList: action.payload,
			}),
			UPDATE_DOCTOR_LIST: (state, action: Action<ExtendedHospitalDoctorResource[]>) => ({
				...state,
				doctorList: action.payload,
			}),
			SET_SELECTED_WEEK: (state, action) => ({
				...state,
				selectedWeek: action.payload,
			}),
			CLEAR_SELECTED_OPTIONS: state => ({
				...state,
			}),
			CLEAR_SELECTED_DOCTOR: state => ({
				...state
			}),
			CLEAR_SEARCH_DOCTOR_LIST: state => ({
				...state,
				searchDoctorList: defaultState.searchDoctorList
			}),
			CHECK_SINGLE_FIRST_DIAGNOSIS_FULFILLED: (state, action: Action<SingleFirstDiagnosisInfo>) => ({
				...state,
				singleFirstDiagnosis: action.payload,
			}),
			FILTER_DOCTOR_LIST_PENDING: (state, action: Action<any[]>) => ({
				...state,
				loading: true,
			}),
			FILTER_DOCTOR_LIST_FULFILLED: (state, action: Action<any[]>) => ({
				...state,
				searchDoctorList: action.payload,
			})
			
		},
		defaultState,
	),
};

const doctorDepartmentRegistrationActionsMap = {
	...formModelConfig.actions,
	getDepartmentList,
	getDoctorList,
	getDoctorDepartmentRef,
	updateDoctorList,
	updateDepartmentList,
	setSelectedOptionsToLocal,
	clearSelectedOptions,
	checkSingleFirstDiagnosis,
	filterDoctorList,
	clearSelectedDoctor,
	clearSearchDoctorList
};

const mapHooksToState = (state: GlobalState) => ({
	departmentList: state.doctorDepartmentRegistration.departmentList,
	doctorList: state.doctorDepartmentRegistration.doctorList,
	searchDoctorList: state.doctorDepartmentRegistration.searchDoctorList,
	selectedDepartment: state.doctorDepartmentRegistration.selectedDepartment,
	selectedBranch: state.doctorDepartmentRegistration.selectedBranch,
	clinicsCodeList: state.doctorDepartmentRegistration.clinicsCodeList,
	singleFirstDiagnosis: state.doctorDepartmentRegistration.singleFirstDiagnosis,
});

type DoctorDepartmentRegistrationSelector = ReturnType<typeof mapHooksToState>;
type DoctorDepartmentRegistrationActionsMap = typeof doctorDepartmentRegistrationActionsMap;

export const useDoctorDepartmentRegistration = () =>
	useRedux<DoctorDepartmentRegistrationSelector, DoctorDepartmentRegistrationActionsMap>(
		mapHooksToState,
		doctorDepartmentRegistrationActionsMap,
	);
