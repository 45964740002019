import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import history from 'store/history';

import { api } from 'util/api';
import { V1UserImageReportListRequestParams } from 'util/api/swaggerApi/data-contracts';

import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';

interface Record {
	uid?: string;
	date?: string;
	description?: string;
	dr?: string;
	photo?: string[];
	video?: string[];
}

export interface MediaType {
	type: string;
	url: string;
	combineIndex: number;
}

export interface ImageReportType {
	id: number;
	date: string;
	rawDate: string;
	description: string;
	dr: string;
	photo: string[];
	institution: string;
	video: string[];
	combineMedia: MediaType[];
	groupedMedia: MediaType[][];
	imgUrl: string;
}

export const getImageReportList = createAction(
	'GET_IMAGE_REPORT',
	(department: string, identity: string, type: string) =>
		async (dispatch: Dispatch, getState: GetState) => {
			const {
				user: {
					userInfo: { patientNo },
				},
			} = getState();

			const params: V1UserImageReportListRequestParams = {
				serial: patientNo || ''
			};

			if (type) {
				params.type = type;
			} else {
				params.department = department;
				params.identity = identity;
			}

			let response;
			const { v1UserImageReportList } = api;

			try {
				response = await v1UserImageReportList(params);
			} catch (e) {
				console.log('image report error', e);
				const { status } = e as { status: number };
				if (status === 401) {
					dispatch(updateAccessToken(''));
					history.push('/');
				}
				return [];
			}

			const { data } = response;

			if (!data?.data) {
				return [];
			}
			let id = 0;
			const processedRecords = data.data.aps?.record?.map((record: Record) => {
				// 格式化日期
				const formattedDate = `${record.date?.slice(0, 4)}/${record.date?.slice(
					4,
					6,
				)}/${record.date?.slice(6)}`;

				let formattedDr = '';
				let formattedInstitution = '';

				// 分割醫生姓名和分院名稱。醫生名稱有可能是空字串，有可能沒有分院名稱
				if (record.dr) {
					const splitDr = record.dr.split('(');
					formattedDr = splitDr[0].trim();
					formattedInstitution = splitDr[1] ? splitDr[1].replace(')', '').trim() : '';
				}

				// 處理照片和影片格式
				let combineMedia = [
					...record.photo?.map(photo => ({ type: 'photo', url: photo })) || [],
					...record.video?.map(video => ({ type: 'video', url: video })) || [],
				];
				combineMedia = combineMedia.map((item, index) => ({...item, combineIndex: index}));

				// 將照片和影片分組，每組5個：[[{..},{..},{..},{..},{..}], [...], ...]
				const groupedMedia = [];
				for (let i = 0; i < combineMedia.length; i += 5) {
					const group = combineMedia.slice(i, i + 5);
					groupedMedia.push(group);
				}

				return {
					id: id++, // eslint-disable-line no-plusplus
					date: formattedDate,
					rawDate: record.date,
					description: record.description,
					dr: formattedDr,
					institution: formattedInstitution,
					photo: record.photo,
					video: record.video,
					combineMedia,
					groupedMedia,
				};
			});

			// 按日期排序
			processedRecords?.sort((a, b) => {
				const dateA = a.rawDate || '';
				const dateB = b.rawDate || '';
				return dateB.localeCompare(dateA);
			});

			return {
				processedRecords,
				url: data?.data?.aps?.url
			};
		},
);

export interface State {
	loading: boolean;
	reports: ImageReportType[];
	imgUrl: string;
}

export const defaultState: State = {
	loading: false,
	reports: [],
	imgUrl: ''
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	imageReports: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_IMAGE_REPORT_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_IMAGE_REPORT_FULFILLED: (state, action: Action<any>) => ({
				...state,
				reports: action.payload.processedRecords,
				imgUrl: action.payload.url,
				loading: false,
			}),
		},
		defaultState,
	),
};

const imageReportActionsMap = {
	getImageReportList,
};

const mapHooksToState = (state: GlobalState) => ({
	imageReports: state.imageReports.reports,
	imgUrl: state.imageReports.imgUrl
});

type ImageReportSelector = ReturnType<typeof mapHooksToState>;
type ImageReportActionsMap = typeof imageReportActionsMap;

export const useImageReport = () =>
	useRedux<ImageReportSelector, ImageReportActionsMap>(mapHooksToState, imageReportActionsMap);
