import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import history from 'store/history';

import { api } from 'util/api';
import { EyeCheckImageListRequestParams } from 'util/api/swaggerApi/data-contracts';

import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';

export interface MediaType {
	type: string;
	url: string;
	combineIndex: number;
  pageBase64: string;
}

export interface EyeCheckImageReportType {
	id: number;
	date: string;
	rawDate: string;
	description: string;
	doctorName: string;
	summary: string;
	reportBase64: string;
	photo: string[];
	institution: string;
	video: string[];
	combineMedia: MediaType[];
	groupedMedia: MediaType[][];
  pageInfo: MediaType[];
}

export const getEyeListReportList = createAction(
	'GET_LIST',
	() =>
		async (dispatch: Dispatch, getState: GetState) => {
			const {
				user: {
					userInfo: { patientNo },
				},
			} = getState();

			const params: EyeCheckImageListRequestParams = {
				// patientNo: '0409449' || ''
        patientNo: patientNo || ''
			};

			let response;
			const { v1EyeReportPage } = api;

			try {
				response = await v1EyeReportPage(params);
			} catch (e) {
				console.log('image report error', e);
				const { status } = e as { status: number };
				if (status === 401) {
					dispatch(updateAccessToken(''));
					history.push('/');
				}
				return [];
			}

			const { data } = response;

			if (!data?.data) {
				return [];
			}
			return data?.data
			// return processedRecords;
		},
);

export interface State {
	loading: boolean;
	reports: any[];
}

export const defaultState: State = {
	loading: false,
	reports: [],
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	optomeryListReports: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_LIST_FULFILLED: (state, action: Action<EyeCheckImageReportType[]>) => ({
				...state,
				reports: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const optomeryReportActionsMap = {
	getEyeListReportList,
};

const mapHooksToState = (state: GlobalState) => ({
	optomeryListReports: state.optomeryListReports.reports,
});

type OptomeryReportSelector = ReturnType<typeof mapHooksToState>;
type OptomeryReportActionsMap = typeof optomeryReportActionsMap;

export const useOptomeryReport = () =>
	useRedux<OptomeryReportSelector, OptomeryReportActionsMap>(mapHooksToState, optomeryReportActionsMap);
