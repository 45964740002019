import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/failure',
	components: () => [import(/* webpackChunkName: 'registrationFailure' */ './component')],
	render: ([RegistrationFailure]) => <RegistrationFailure />,
	onEnter: async () => {
		console.log('on Enter registration failure');
	},
};

export default routes;
