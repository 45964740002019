import { CustomRoute } from 'util/hook/useRouter';

import InpatientListRoute from './InpatientList';
import SurgeryListRoute from './SurgeryList';
import NewbornListRoute from './NewbornList';
import BeautyClinicRoute from './BeautyClinic';

const routes: CustomRoute = {
	path: '/staff',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [InpatientListRoute, SurgeryListRoute, NewbornListRoute, BeautyClinicRoute],
};

export default routes;
