import React from 'react';

import { CustomRoute } from 'util/hook/useRouter';

import { getHealthReportList } from 'models/healthReport';
import { checkAuthGuard } from 'models/auth';
import { ROUTE_PATHS } from 'models/sideMenu';

const routes: CustomRoute = {
	path: '/health-report',
	components: () => [import(/* webpackChunkName: 'healthReport' */ './component')],
	render: ([HealthReport]) => <HealthReport />,
	onEnter: async ({ store }) => {
		const allowEnter = checkAuthGuard(store, `/${ROUTE_PATHS.HEALTH_REPORT}`);
		if (allowEnter) {
			await store.dispatch(getHealthReportList());
		}
	},
};

export default routes;
