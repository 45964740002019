import React from 'react';
import classnames from 'classnames';

import Dropdown from 'components/atoms/Dropdown';
import Avatar, { AvatarGender, AvatarIdentity } from 'components/atoms/Avatar';

import { useAuth } from 'models/auth';

import ArrowIcon from 'images/icon/chevron.inline.svg';

import styles from './index.module.css';

interface DropdownAvatarProperty {
	name: string;
	identity?: AvatarIdentity;
	gender?: AvatarGender;
}

const DropdownAvatar: React.FC<DropdownAvatarProperty> = ({
	name = '',
	identity,
	gender,
}) => {
	const [{ isWebview }, { logout }] = useAuth();

	return !isWebview ? (
		<Dropdown
			styleMap={{
				dropdownPanel: styles.dropdownPanel,
			}}
			renderMainComponent={({ isOpen }) => (
				<div className={classnames(styles.avatarInfo, isOpen && styles.open)}>
					{/* <Avatar identity={identity} gender={gender} /> */}
					<div className={styles.name}>{name}</div>
					<div className={classnames(styles.icon, isOpen && styles.open)}>
						<ArrowIcon />
					</div>
				</div>
			)}
			renderPanelComponent={({ onClosePanel }) => (
				<button
					type="button"
					className={styles.logoutBtn}
					onClick={() => {
						onClosePanel();
						logout();
					}}
				>
					登出
				</button>
			)}
		/>
	) : (
		<div className={classnames(styles.avatarInfo, isWebview && styles.disabled)}>
			{/* <Avatar identity={identity} gender={gender} /> */}
			<div className={styles.name}>{name}</div>
		</div>
	);
};

export default DropdownAvatar;
