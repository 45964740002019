import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { initWeekDayData } from 'models/outpatientRegistration';
import { getDepartmentList, getBranchList } from 'models/departmentRegistration';


const routes: CustomRoute = {
	path: '/start',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'registrationStart' */ './component')],
			render: ([RegistrationStart]) => <RegistrationStart />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter registrations start');
				store.dispatch(initWeekDayData());
				await store.dispatch(getDepartmentList());
				await store.dispatch(getBranchList());
				const children = await next();
				return children;
			},
		},
	],
};
export default routes;
