import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import history from 'store/history';
import { nanoid } from 'nanoid';

import { api } from 'util/api';
import { OperatingRoomAppointmentListResource } from 'util/api/swaggerApi/data-contracts';
import { SelectorValueType } from './inpatientList';
import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';

interface SurgeryRoom {
	id: string;
	value: string;
	optionName: string;
	relativeOptions: [];
}

interface SurgeryRoomListById {
  [key: string]: OperatingRoomAppointmentListResource[];
}

export interface SurgerySelectorType {
	roomValue: string;
	doctor: string;
}

export interface State {
	loading: boolean;
	surgeryRoomList: SurgeryRoom[];
	surgeryAppointmentList: OperatingRoomAppointmentListResource[];
	surgeryRoomListsById: SurgeryRoomListById;
	currentListDetail: OperatingRoomAppointmentListResource;
	selectorValue: SurgerySelectorType;
	currentFilteredList: OperatingRoomAppointmentListResource[];
}

export const defaultState: State = {
	loading: false,
	surgeryRoomList: [],
	surgeryAppointmentList: [],
	surgeryRoomListsById: {},
	currentListDetail: {},
	selectorValue: {roomValue: 'all', doctor: 'all'} as SurgerySelectorType,
	currentFilteredList: [],
};

export const getSurgeryRoomList = createAction(
	'GET_SURGERY_ROOM_LIST', () =>
		async (dispatch: Dispatch) => {
			const { v1StaffOperatingRoomListList } = api;
			try {
				const { data } = await v1StaffOperatingRoomListList();
				const firstOption = { id: nanoid(10), value: 'all', optionName: '所有開刀房', relativeOptions: [] };

				const convertValue = (value: string) => {
					if (value === '内湖') {
						return '內湖';
					}
					return value;
				};
				// 嘉禾要合併到安和開刀房
				const formattedData = [
					firstOption,
					...(data?.data?.filter(room => room.name !== '嘉禾')
						.map(room => ({
							id: nanoid(10),
							value: convertValue(room.name || ''),
							optionName: convertValue(room.name || ''),
							relativeOptions: [],
						})) || [])
				];

				const roomLists = formattedData.reduce<SurgeryRoomListById>((acc, room) => {
					acc[room.id] = [];
					return acc;
				}, {});
				dispatch({ type: 'SET_SURGERY_ROOM_LISTS_BY_ID', payload: roomLists });
				return formattedData;

			} catch (e) {
				console.log('error', e);
				const { status } = e as { status: number };
				if (status === 401) {
					dispatch(updateAccessToken(''));
					history.push('/');
				}

				return [];
			}
		},
);

export const getSurgeryAppointmentList = createAction(
	'GET_SURGERY_APPOINTMENT_LIST', () =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { surgeryList: { surgeryRoomList, surgeryRoomListsById } } = getState();
			const { v1StaffOperatingRoomAppointmentListList } = api;
			const updatedSurgeryRoomListsById = { ...surgeryRoomListsById };

			try {
				const { data } = await v1StaffOperatingRoomAppointmentListList();

				if (Array.isArray(data?.data)) {
					data?.data?.forEach((appointment: OperatingRoomAppointmentListResource) => {
						const operatingRoomName = appointment.operatingRoom === '嘉禾' ? '安和' : appointment.operatingRoom;

						const surgeryRoomId = surgeryRoomList.find(room => room.value === operatingRoomName)?.id;

						if (surgeryRoomId) {
							updatedSurgeryRoomListsById[surgeryRoomId] = updatedSurgeryRoomListsById[surgeryRoomId] || [];
							updatedSurgeryRoomListsById[surgeryRoomId].push(appointment);
						}
					});

					dispatch({ type: 'SET_SURGERY_ROOM_LISTS_BY_ID', payload: updatedSurgeryRoomListsById });
				}

				return data?.data || [];

			} catch (e) {
				console.log('error', e);
				const { status } = e as { status: number };
				if (status === 401) {
					dispatch(updateAccessToken(''));
					history.push('/');
				}

				return [];
			}
		},
);

export const filterSurgeryList = createAction(
	'FILTER_SURGERY_LIST',
	() => (_: Dispatch, getState: GetState) => {
		const { surgeryList } = getState();
		const roomValue = surgeryList.selectorValue.roomValue;
		const doctor = surgeryList.selectorValue.doctor;
		if (roomValue === 'all' && doctor === 'all') {
			return surgeryList.surgeryAppointmentList;
		}
		if (roomValue === '' && doctor === '') {
			return [];
		}

		let filteredList = surgeryList.surgeryAppointmentList;
		let list = surgeryList.surgeryAppointmentList;

		if (roomValue !== 'all') {
			const matchingRoom = surgeryList.surgeryRoomList.find(room => room.value === roomValue);
			if (matchingRoom) {
				filteredList = surgeryList.surgeryRoomListsById[matchingRoom.id];
			}
		}

		if (doctor !== 'all') {
			filteredList = filteredList.filter(item => item.surgeon === doctor);
			// if (filteredList.length === 0) {
			// 	filteredList = list;
			// }
		} else {
			// filteredList = filteredList.filter(item => item.surgeon === doctor);
		}

		return filteredList;
	},
);

export const setCurrentListDetail = createAction(
	'SET_CURRENT_LIST_DETAIL', (detail: OperatingRoomAppointmentListResource) => detail,
);

export const setSurgerySelectorValue = createAction(
	'SET_SURGERY_SELECTOR_VALUE',
	(type: 'roomValue' | 'doctor', value: string) => ({type, value}),
);

export const initSurgeryDoctorValue = createAction(
	'INIT_VALUE', () => async(dispatch: Dispatch, getState: GetState) => {
		const { user: { staffPermissionInfo } } = getState();

		if (staffPermissionInfo.isDoctor) {
			dispatch(setSurgerySelectorValue('doctor', staffPermissionInfo.defaultSelectorDoctorValue));
		}
		dispatch(filterSurgeryList());
	}
);

export const cleanSurgerySelectorValue = createAction('CLEAN_SURGERY_SELECTOR_VALUE');

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	surgeryList: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_SURGERY_ROOM_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_SURGERY_ROOM_LIST_FULFILLED: (state, action: Action<SurgeryRoom[]>) => ({
				...state,
				surgeryRoomList: [...action.payload],
				loading: false,
			}),
			GET_SURGERY_APPOINTMENT_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_SURGERY_APPOINTMENT_LIST_FULFILLED: (state, action: Action<OperatingRoomAppointmentListResource[]>) => ({
				...state,
				surgeryAppointmentList: [...action.payload],
				loading: false,
			}),
			SET_SURGERY_ROOM_LISTS_BY_ID: (state, action: Action<SurgeryRoomListById>) => ({
				...state,
				surgeryRoomListsById: action.payload,
			}),
			SET_CURRENT_LIST_DETAIL: (state, action: Action<OperatingRoomAppointmentListResource>) => ({
				...state,
				currentListDetail: action.payload,
			}),
			SET_SURGERY_SELECTOR_VALUE: (state, action: Action<SelectorValueType>) => ({
				...state,
				selectorValue: {
					...state.selectorValue,
					[action.payload.type]: action.payload.value,
				},
			}),
			FILTER_SURGERY_LIST: (state, action: Action<OperatingRoomAppointmentListResource[]>) => ({
				...state,
				currentFilteredList: [...action.payload],
				loading: false,
			}),
			CLEAN_SURGERY_SELECTOR_VALUE: state => ({
				...state,
				selectorValue: {
					roomValue: 'all', doctor: 'all'
				},
			}),
		},
		defaultState,
	),
};

const surgeryListActionsMap = {
	getSurgeryRoomList,
	getSurgeryAppointmentList,
	setCurrentListDetail,
	setSurgerySelectorValue,
	filterSurgeryList,
	cleanSurgerySelectorValue,
	initSurgeryDoctorValue,
};

const mapHooksToState = (state: GlobalState) => ({
	surgeryRoomList: state.surgeryList.surgeryRoomList,
	surgeryAppointmentList: state.surgeryList.surgeryAppointmentList,
	surgeryRoomListsById: state.surgeryList.surgeryRoomListsById,
	currentListDetail: state.surgeryList.currentListDetail,
	selectorValue: state.surgeryList.selectorValue,
	currentFilteredList: state.surgeryList.currentFilteredList,
});

type SurgeryListSelector = ReturnType<typeof mapHooksToState>;
type SurgeryListActionsMap = typeof surgeryListActionsMap;

export const useSurgeryList = () =>
	useRedux<SurgeryListSelector, SurgeryListActionsMap>(mapHooksToState, surgeryListActionsMap);
