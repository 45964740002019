import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';

import { api } from 'util/api';
import {
	STORAGE_KEY,
	StorageType,
	setItemToStorage,
	removeItemInStorage,
} from 'util/hook/useStorage';
import { FormControl } from 'util/formSystem/formControl';
import setupFormModel from 'util/formSystem/setupForm';
import { GetState, State as GlobalState } from './reducers';

export interface State {
	loading: boolean;
	typeList: any[];
	selectedType: FormControl<any>;
}

export const defaultState: State = {
	loading: false,
	typeList: [],
	selectedType: {} as FormControl<any>,
};

export const formModelConfig = setupFormModel<State>('REGISTRATION_FORM', (getState: GetState) => {
	const { courseTypeRegistration } = getState();
	return courseTypeRegistration;
});

export const getTypeList = createAction(
	'GET_TYPE_LIST',
	() => async (dispatch: Dispatch, getState: GetState) => {
		const { v1CourseOptList, v1StaffCourseOptList } = api;
		console.log(getState())
		const { user: { isAppStaff } } = getState();

		try {
			const { data } = isAppStaff ? await v1StaffCourseOptList() : await v1CourseOptList();
			// if (isAppStaff) {
			// 	const { data } = await v1StaffCourseOptList();
			// 	return data?.data;
			// }

			// const { data } = await v1CourseOptList();
			
			return data?.data;
				
		} catch (e) {
			console.log('getTypeList error', e);

			return [];
		}
	},
);

const setSelectedOptionsToLocal = createAction('SET_SELECTED_OPTIONS_TO_LOCAL', () => (_:Dispatch, getState: any) => {
	const { courseTypeRegistration: { selectedType } } = getState();
	setItemToStorage(STORAGE_KEY.SELECTED_TYPE, selectedType.value, StorageType.LOCAL);
});

export const clearSelectedOptions = createAction('CLEAR_SELECTED_OPTIONS', () => (dispatch: Dispatch) => {
	dispatch(formModelConfig.actions.setFormCtrlValue('selectedType', {}));
	removeItemInStorage(STORAGE_KEY.SELECTED_TYPE, StorageType.LOCAL);
});

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	courseTypeRegistration: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			...formModelConfig.reducers,
			GET_TYPE_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_TYPE_LIST_FULFILLED: (
				state, 
				action: Action<any[]>
			) => ({
				...state,
				typeList: action.payload,
				loading: false,
			}),
			SET_SELECTED_WEEK: (state, action) => ({
				...state,
				selectedWeek: action.payload,
			}),
			CLEAR_SELECTED_OPTIONS: state => ({
				...state,
			}),
			
		},
		defaultState,
	),
};

const typeRegistrationActionsMap = {
	...formModelConfig.actions,
	getTypeList,
	setSelectedOptionsToLocal,
	clearSelectedOptions,
};

const mapHooksToState = (state: GlobalState) => ({
	typeList: state.courseTypeRegistration.typeList,
	selectedType: state.courseTypeRegistration.selectedType,
	loading: state.courseTimeRegistration.loading,
});

type TypeRegistrationSelector = ReturnType<typeof mapHooksToState>;
type TypeRegistrationActionsMap = typeof typeRegistrationActionsMap;

export const useTypeRegistration = () =>
	useRedux<TypeRegistrationSelector, TypeRegistrationActionsMap>(
		mapHooksToState,
		typeRegistrationActionsMap,
	);
