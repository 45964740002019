/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import classnames from 'classnames';
import history from 'store/history';

import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';

import { useRouting } from 'models/routing';
import { RouteMenu, useSideMenu, ROUTE_PATHS } from 'models/sideMenu';

import Triangle from 'images/icon/triangle-down.inline.svg';
import {
	useDoctorOutpatientRegistration,
} from 'models/doctorOutpatientRegistration';

import styles from './index.module.css';

interface MenuProperty {
	menu: RouteMenu;
	parentLink?: string;
	onMenuClick?: (id: string, e?: React.MouseEvent) => void;
}

interface SideMenuProperty {
	onMenuClick?: (id: string, e?: React.MouseEvent) => void;
	isMenuOpen?: boolean;
}

const Menu: React.FC<MenuProperty> = ({ menu, parentLink, onMenuClick = () => {} }) => {
	const [{ routing }] = useRouting();
	const [{ menus }, { toggleMenu }] = useSideMenu();

	const Icon = menu.icon;
	const isOpen = menus.some(m => m.id === menu.id && m.isOpen);
	const targetLink = parentLink && !menu.isExternalLink ? `${parentLink}${menu.to}` : menu.to;

	const hasSubMenus = useMemo(() => {
		const childMenushowOnNav = menu.subMenus.some(
			submenu => submenu.isShowOnNav && menu.allowPermission,
		);
		return menu.subMenus.length > 0 && childMenushowOnNav;
	}, [menu]);

	const isMatch = () => {
		if (isOpen) {
			return false;
		}
		return routing.pathname.startsWith(targetLink);
	};

	return (
		<li key={menu.id} className={classnames(styles.menuItem)}>
			<Link
				className={classnames(styles.menuItemLink, {
					[styles.active]: isMatch(),
					[styles.open]: isOpen,
				})}
				type={menu.isExternalLink ? 'external' : 'internal'}
				to={hasSubMenus ? '' : targetLink}
				onClick={() => {
					if (hasSubMenus) {
						toggleMenu(menu.id);
					} else {
						onMenuClick(menu.id);
					}
				}}
			>
				{menu.iconPosition === 'left' && <span className={styles.icon}>{Icon && <Icon />}</span>}
				<span>{menu.title}</span>
				{menu.iconPosition === 'right' && <span className={styles.icon}>{Icon && <Icon />}</span>}
				{hasSubMenus && (
					<span className={classnames(styles.icon, styles.triggerIcon)}>
						<Triangle />
					</span>
				)}
			</Link>
			{hasSubMenus && (
				<div className={classnames(styles.expandable, isOpen && styles.open)}>
					<ul className={styles.subMenus}>
						{menu.subMenus
							.filter(submenu => submenu.isShowOnNav && submenu.allowPermission)
							.map(submenu => (
								<Menu
									key={submenu.id}
									menu={submenu}
									parentLink={targetLink}
									onMenuClick={onMenuClick}
								/>
							))}
					</ul>
				</div>
			)}
		</li>
	);
};

const SideMenu: React.FC<SideMenuProperty> = ({ isMenuOpen, onMenuClick = () => {} }) => {
	const [{ routeMenus }] = useSideMenu();
	const [
		{},
		{
			clearSelectedOutpatientInfo,
		},
	] = useDoctorOutpatientRegistration();

	return (
		<ul className={styles.sideMenu}>
			<div>
				{routeMenus
					.filter(menu => menu.isShowOnNav && menu.allowPermission)
					.map(menu => (
						<Menu key={menu.id} menu={menu} onMenuClick={onMenuClick} />
					))}
			</div>
			{isMenuOpen && (
				<Button
					size="l"
					onClick={() => {
						history.push(`/${ROUTE_PATHS.APPOINTMENTS}`);
						onMenuClick('');
					}}
				>
					預約及掛號
				</Button>
			)}
			{isMenuOpen && (
				<Button
					className={styles.buttonRed}
					size="l"
					onClick={() => {
						clearSelectedOutpatientInfo();
						// history.push('/doctor-registration?step=1');
						history.push(`/${ROUTE_PATHS.APPOINTMENTS}/${ROUTE_PATHS.REGISTRATIONS}/${ROUTE_PATHS.DOCTOR_REGISTRATION}?step=1`);
						onMenuClick('');
					}}
				>
					尋找醫師
				</Button>
			)}
		</ul>
	);
};

export default SideMenu;
