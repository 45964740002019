/* eslint-disable no-restricted-syntax */
import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import { nanoid } from 'nanoid';
import history from 'store/history';
import dayjs from 'dayjs';

import { ApiError, api } from 'util/api';
import { FormControl } from 'util/formSystem/formControl';
import { Validators } from 'util/formSystem/validatorFn';
import setupFormModel from 'util/formSystem/setupForm';
import { V1AuthSignUpCreateRequestPayload } from 'util/api/swaggerApi/data-contracts';
import { getRawValue } from 'util/formSystem/formOperators';
import { EMAIL_REGEXP } from 'util/regexp';

import { ToastStatus, openToast } from './toast';
import { AlertStatus, openAlert } from './alert';

import { GetState, State as GlobalState } from './reducers';

export interface State {
	loading: boolean;
	twid: FormControl<V1AuthSignUpCreateRequestPayload['twid']>;
	name: FormControl<V1AuthSignUpCreateRequestPayload['name']>;
	birthday: FormControl<V1AuthSignUpCreateRequestPayload['birthday']>;
	phone: FormControl<V1AuthSignUpCreateRequestPayload['phone']>;
	email: FormControl<V1AuthSignUpCreateRequestPayload['email']>;
}

export const defaultState: State = {
	loading: false,
	twid: {
		value: '',
		errors: null,
		options: {
			validators: [Validators.require, Validators.pattern(/^[A-Za-z0-9]{10}$/)],
		},
	},
	name: {
		value: '',
		errors: null,
		options: {
			validators: [Validators.require],
		},
	},
	birthday: {
		value: '',
		errors: null,
		options: {
			validators: [Validators.require],
		},
	},
	phone: {
		value: '',
		errors: null,
		options: {
			validators: [Validators.require, Validators.pattern(/^09\d{8}$/)],
		},
	},
	email: {
		value: '',
		errors: null,
		options: {
			validators: [Validators.require, Validators.pattern(EMAIL_REGEXP)],
		},
	},
};

export const formModelConfig = setupFormModel<State>('SIGN_UP_FORM', (getState: GetState) => {
	const { signUp } = getState();
	return signUp;
});

export const submitSignUpForm = createAction(
	'SUBMIT_SIGN_UP_FORM',
	(sendType: V1AuthSignUpCreateRequestPayload['send_type']) =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { v1AuthSignUpCreate } = api;
			const { signUp } = getState();
			const signUpRawValue = getRawValue<State>(signUp) as V1AuthSignUpCreateRequestPayload & { loading: boolean; };
			const signUpParams = {
				...signUpRawValue,
				twid: signUpRawValue.twid.toUpperCase(),
				birthday: dayjs(signUpRawValue.birthday).format('YYYY-MM-DD'),
				send_type: sendType,
			};

			dispatch({ type: 'SUBMIT_SIGN_UP_FORM_PENDING' });

			try {
				await v1AuthSignUpCreate(signUpParams);

				const deviceName = sendType === 'SMS' ? '手機' : '電子信箱';
				dispatch(openAlert({
					status: AlertStatus.SUCCESS,
					title: '密碼已發送',
					content: `密碼已發送，請至${deviceName}查詢`,
					buttonText: '前往登入頁',
					buttonHandler: () => {
						history.push('/');
						dispatch(clearSignUpForm());
					},
				}));
			} catch (error) {
				const apiError = (error as ApiError).error;
				const status = apiError?.status || -1;
				const message = apiError?.message || '';
				const extra = apiError?.extra || {};
				const extraMessageList = Object.values(extra).flat().join(' ');

				if (status === 400) {
					dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: '身分證字號重複註冊' }));
				} else if (status === 422) {
					dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: extraMessageList }));
				} else {
					dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: message }));
				}
			}
			dispatch({ type: 'SUBMIT_SIGN_UP_FORM_FULFILLED' });
		}
);

export const clearSignUpForm = createAction('CLEAR_SIGN_UP_FORM');

export const reducer = {
	signUp: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			...formModelConfig.reducers,
			SUBMIT_SIGN_UP_FORM_PENDING: state => ({
				...state,
				loading: true,
			}),
			SUBMIT_SIGN_UP_FORM_FULFILLED: state => ({
				...state,
				loading: false,
			}),
			CLEAR_SIGN_UP_FORM: () => ({
				...defaultState,
			}),
		},
		defaultState,
	),
};

const signUpActionsMap = {
	...formModelConfig.actions,
	submitSignUpForm,
	clearSignUpForm,
};

const mapHooksToState = (state: GlobalState) => ({
	signUp: state.signUp,
});

type SignUpSelector = ReturnType<typeof mapHooksToState>;
type SignUpActionsMap = typeof signUpActionsMap;

export const useSignUp = () =>
	useRedux<SignUpSelector, SignUpActionsMap>(mapHooksToState, signUpActionsMap);
