import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import history from 'store/history';

import { api } from 'util/api';
import { NearsightedTrackMedicineRequestParams } from 'util/api/swaggerApi/data-contracts';

import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';

interface Record {
	uid?: string;
	date?: string;
	description?: string;
	dr?: string;
	photo?: string[];
	video?: string[];
}

export interface MediaType {
	type: string;
	url: string;
	combineIndex: number;
}

export interface NearsightedTrackMedicineType {
	Index: string,
  formatter: any,
  series: string[],
  tooltip: object,
  xAxis: object,
  yAxis: object,
  type: string
}

let allReport: any = []
let allReport1: any = []

export const getNearsightedTrackListReportList = createAction(
	'GET_TRACK_MEDICINE',
	() =>
		async (dispatch: Dispatch, getState: GetState) => {
			const {
				user: {
					userInfo: { patientNo },
				},
			} = getState();

			const params = {
				patientNo: patientNo || ''
			};

			let response;
			const { v1EyeReportTrack } = api;

			try {
				response = await v1EyeReportTrack(params);
			} catch (e) {
				console.log('Train Track report error', e);
				const { status } = e as { status: number };
				if (status === 401) {
					dispatch(updateAccessToken(''));
					history.push('/');
				}
				return null;
			}

			const { data } = response;
			let date: any = [];
			let OS: any = [];
			let OD: any = [];
			let date1: any = [];
			let OS1: any = [];
			let OD1: any = [];

			if (!data?.data) {
				return null;
			}

			let processedRecords = {};
			let processedRecords1 = {};
			const reportInfo = data?.data?.trackProjects.filter((item:any) => item.projectName === '近視控制(藥物)');
			if (reportInfo.length > 0) {
				const reportInfoObj = reportInfo[0].chart;
				reportInfoObj.forEach((name:any) => {
					data?.data?.correspondingValue.forEach((item: any) => {
						if (item.chart === name && item.chart === '度數(D)') {
							item.value.forEach((innerItem: any) => {
								date.push(innerItem.date);
								OS.push(innerItem.OS);
								OD.push(innerItem.OD);
							})
						}
						if (item.chart === name && item.chart === '眼軸長(mm)') {
							item.value.forEach((innerItem: any) => {
								date1.push(innerItem.date);
								OS1.push(innerItem.OS);
								OD1.push(innerItem.OD);
							})
						}
					})
		
					processedRecords = {
						xAxis: {
							type: 'category',
							data: date,
							splitLine: {
								show: true,
								interval: 0
							},
							axisLine: {
								show: true
							},
						},
						yAxis: {
							type: 'value',
							scale: true,
						},
						series: [
							{
								name: '左眼',
								data: OS,
								type: 'line',
								color: '#00AFC5',
								tooltip: {
									formatter(params:any) {
										return `${params.seriesName} ${params.value} (D)<br />日期 ${params.name}<br />`;
									}
								},
							},
							{
								name: '右眼',
								data: OD,
								type: 'line',
								color: '#F2656A',
								tooltip: {
									formatter(params:any) {
										return `${params.seriesName} ${params.value} (D)<br />日期 ${params.name}<br />`;
									}
								},
							}
						],
						tooltip: {
							trigger: 'item',
							backgroundColor: 'rgba(0, 0, 0, .6)',
							borderWidth: 0,
							textStyle: {
								color: '#fff'
							}
						},
						
						formatter(params:any) {
							return `${params[0].seriesName} ${params[0].value}(D)<br />${params[0].name}<br />`;
						}
					}
					processedRecords1 = {
						xAxis: {
							type: 'category',
							data: date1,
							splitLine: {
								show: true,
								interval: 0
							},
							axisLine: {
								show: true
							},
						},
						yAxis: {
							type: 'value',
							scale: true,
						},
						series: [
							{
								name: '左眼',
								data: OS1,
								type: 'line',
								color: '#00AFC5',
								tooltip: {
									formatter(params:any) {
										return `${params.seriesName} ${params.value} (mm)<br />日期 ${params.name}<br />`;
									}
								},
							},
							{
								name: '右眼',
								data: OD1,
								type: 'line',
								color: '#F2656A',
								tooltip: {
									formatter(params:any) {
										return `${params.seriesName} ${params.value} (mm)<br />日期 ${params.name}<br />`;
									}
								},
							}
						],
						tooltip: {
							trigger: 'item',
							backgroundColor: 'rgba(0, 0, 0, .6)',
							borderWidth: 0,
							textStyle: {
								color: '#fff'
							}
						},
						
						formatter(params:any) {
							return `${params[0].seriesName} ${params[0].value}(D)<br />${params[0].name}<br />`;
						}
					}
		
					allReport = JSON.parse(JSON.stringify(processedRecords));
					allReport1 = JSON.parse(JSON.stringify(processedRecords1));
				});
				return {
					processedRecords,
					processedRecords1
				}
			} else {
				return null;
			}
		},
);

export const filterReport = createAction(
	'FILTER_REPORT',
	(direction: string, isShow: boolean) =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { nearsightedTrackMedicineReports: { reports } } = getState();
			const processedRecords = reports

			if (direction === 'left' && isShow) {
				processedRecords.series[0].data = allReport.series[0].data;
			} else if (direction === 'left' && !isShow) {
				processedRecords.series[0].data = [];
			} else if (direction === 'right' && isShow) {
				processedRecords.series[1].data = allReport.series[1].data;
			} else {
				processedRecords.series[1].data = [];
			}
			return processedRecords;
		},
);

export const filterReport1 = createAction(
	'FILTER_REPORT_1',
	(direction: string, isShow: boolean) =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { nearsightedTrackMedicineReports: { reports1 } } = getState();
			const processedRecords = reports1

			if (direction === 'left' && isShow) {
				processedRecords.series[0].data = allReport1.series[0].data;
			} else if (direction === 'left' && !isShow) {
				processedRecords.series[0].data = [];
			} else if (direction === 'right' && isShow) {
				processedRecords.series[1].data = allReport1.series[1].data;
			} else {
				processedRecords.series[1].data = [];
			}
			return processedRecords;
		},
);

export interface State {
	loading: boolean;
	reports: any;
	reports1: any;
}

export const defaultState: State = {
	loading: false,
	reports: {},
	reports1: {}
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	nearsightedTrackMedicineReports: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_TRACK_MEDICINE_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_TRACK_MEDICINE_FULFILLED: (state, action: Action<any>) => ({
				...state,
				reports: action.payload?.processedRecords,
				reports1: action.payload?.processedRecords1,
				loading: false,
			}),
			FILTER_REPORT_PENDING: state => ({
				...state,
				loading: true,
			}),
			FILTER_REPORT_FULFILLED: (state, action: Action<NearsightedTrackMedicineType>) => ({
				...state,
				reports: action.payload,
				loading: false,
			}),
			FILTER_REPORT_1_PENDING: state => ({
				...state,
				loading: true,
			}),
			FILTER_REPORT_1_FULFILLED: (state, action: Action<NearsightedTrackMedicineType>) => ({
				...state,
				reports1: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const nearsightedTrackMedicineReportActionsMap = {
	getNearsightedTrackListReportList,
	filterReport,
	filterReport1
};

const mapHooksToState = (state: GlobalState) => ({
	nearsightedTrackMedicineReports: state.nearsightedTrackMedicineReports.reports,
	nearsightedTrackMedicineReports1: state.nearsightedTrackMedicineReports.reports1,
});

type NearsightedTrackMedicineReportSelector = ReturnType<typeof mapHooksToState>;
type NearsightedTrackMedicineReportActionsMap = typeof nearsightedTrackMedicineReportActionsMap;

export const useNearsightedTrackMedicineReport = () =>
	useRedux<NearsightedTrackMedicineReportSelector, NearsightedTrackMedicineReportActionsMap>(mapHooksToState, nearsightedTrackMedicineReportActionsMap);
